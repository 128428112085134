import { AutoCompleteSelectUI, ButtonUI, DialogUI, FormLabelUI } from '../Interface';

export const StatusDialog = ({ open, handleClose, submenu, filter, saveView }) => {
  const { filterVal, setFilterVal } = filter;
  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent="Státusz választó"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <div className="mt-5">
        <div className="text-labelColor">
          <div className="w-[750px]">
            <AutoCompleteSelectUI
              label={<FormLabelUI text="Státusz" />}
              onChange={(_event, newVal, _reason) => {
                setFilterVal((prev) => ({
                  ...prev,
                  statusId: newVal,
                }));
                saveView('ganttFilter', { status: newVal });
              }}
              selectedValue={filterVal.statusId}
              disabled={false}
              id={'selectedStatus'}
              table={['statusitems', 'status']}
              listType={{ id: 'itemId', name: 'itemName' }}
              joins={'statusitems.statusId = status.statusId'}
              conditions={[`status.subMenuId = ${submenu}`, `statusitems.archive='N'`]}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              multiple
            />
          </div>
          <div className="flex justify-center mt-5">
            <ButtonUI text="Bezárás" color="blue-gray" variant="gradient" onClick={handleClose} />
          </div>
        </div>
      </div>
    </DialogUI>
  );
};
