import React from 'react';
import { Field } from 'formik';

import { FormErrorUI, FormLabelUI, AutoCompleteSelectUI, DatePickerUI } from '../Interface';

const AttendanceFields = ({ viewField, selectedValues, values, validateField, setFieldValue, errors, submenuId }) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
      <div className="col-span-4 md:col-span-2">
        <Field
          type="date"
          name="date"
          component={DatePickerUI}
          value={values.date}
          fullWidth
          disabled={viewField}
          label={<FormLabelUI text="Dátum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.date} />}
          onChange={(date) => {
            setFieldValue('date', date).then(() => {
              validateField('date');
            });
          }}
          InputLabelProps={{ shrink: values.date !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="projectId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Project" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.projectId} />}
          onChange={(_e, newVal) => {
            setFieldValue('projectId', newVal?.value ?? null).then(() => {
              validateField('projectId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.projectId !== '' }}
          selectedValue={values.projectId}
          selectedLabelValue={selectedValues?.project?.projectName}
          table={'project'}
          listType={{ id: 'projectId', name: 'projectName' }}
          conditions={[`project.archive = 'N'`]}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="statusId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Státusz" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.statusId} />}
          onChange={(_e, newVal) => {
            setFieldValue('statusId', newVal?.value ?? null).then(() => {
              validateField('statusId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.statusId !== '' }}
          selectedValue={values.statusId}
          selectedLabelValue={selectedValues?.status?.itemName}
          table={['statusitems', 'status']}
          listType={{ id: 'itemId', name: 'itemName' }}
          joins={'statusitems.statusId = status.statusId'}
          conditions={[`statusitems.archive = 'N'`, `status.subMenuId = ${submenuId}`]}
        />
      </div>
    </div>
  );
};

export default AttendanceFields;
