import React from 'react';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { FormHelperText, InputLabel } from '@mui/material';

const SelectUI = (props) => {
  const { option, label, helperText } = props;
  return (
    <FormControl variant="standard" sx={{ width: '100%' }}>
      <InputLabel>{label}</InputLabel>
      <Select
        MenuProps={{
          style: {
            zIndex: 9999,
          },
        }}
        {...props}
      >
        {option &&
          option.map((item, index) => (
            <MenuItem key={index} value={item.value ?? item}>
              {item.label ?? item.value ?? item}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectUI;
