import React, { useState, useEffect } from 'react';

import appService from '../../service/app.service';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import { AutoCompleteSelectUI, ButtonUI, DialogUI } from '../Interface';

const RoleModify = (props) => {
  const [permission, setPermission] = useState(props.hasPermission);
  const [canEdit, setCanEdit] = useState(true);
  //const [selectedPerm, setSelectedPerm] = useState({});

  useEffect(() => {
    setPermission(props.hasPermission);
    const findedPerm = props.roleSubMenuPermissions.find((f) => f.subMenuId === props.selectedSubMenu);
    //setSelectedPerm(findedPerm);
    setCanEdit(findedPerm?.roleSubmenuPermission[0]?.canEdit ?? true);
  }, [props]);

  const handleChangeRole = async () => {
    /*const currentOption = options.find((opt) => opt.value === props.hasPermission);
    const selectedOption = options.find((opt) => opt.value === permission);

   if (currentOption.value === selectedOption.value) {
      addNotification({
        content: 'A jogosultság értéke nem változott!',
        type: NotificationType.WARNING,
      });
      return;
    }*/

    appService
      .updateSubMenuPermission(props.roleId, props.selectedSubMenu, permission, canEdit)
      .then(() => {
        const updatedSubMenuList = props.roleSubMenuPermissions.map((permission) =>
          permission.subMenuId === props.selectedSubMenu ? { ...permission, hasPermission: permission } : permission
        );
        props.setRoleSubMenuPermissions(updatedSubMenuList);
        props.setRefreshPage(!props.refreshPage);
        props.onHide();
      })
      .finally(() => {});
  };

  const options = [
    { value: true, label: 'Jogosult' },
    { value: false, label: 'Nem jogosult' },
  ];

  return (
    <DialogUI open={props.show} headerContent={'Jogosultság módosítás'} fullWidth>
      <div className="flex justify-center">
        <div className="mt-2 mx-2 w-2/5">
          <AutoCompleteSelectUI
            value={options.find((opt) => opt.value === permission)}
            onChange={(_event, newValue) => {
              setPermission(newValue.value);
            }}
            options={options}
            getOptionLabel={(option) => option.label}
            label="Jogosultság beállítása"
          />
        </div>
        <div className="mt-2 mx-2 w-2/5">
          <AutoCompleteSelectUI
            value={options.find((opt) => opt.value === canEdit)}
            onChange={(_event, newValue) => {
              setCanEdit(newValue.value);
            }}
            options={options}
            getOptionLabel={(option) => option.label}
            label="Szerkesztheti"
          />
        </div>
      </div>
      <div className="px-4 mt-8 flex justify-center gap-3">
        <ButtonUI text="Hozzáadás" type="button" className="bg-success" onClick={handleChangeRole}>
          Mentés
        </ButtonUI>

        <ButtonUI text="Mégse" type="button" onClick={props.onHide} color="blue-gray" variant="gradient"></ButtonUI>
      </div>
    </DialogUI>
  );
};

export default RoleModify;
