import React, { useEffect, useState } from 'react';

import { useFormikContext } from 'formik';

import { PropertyType } from '../../config/property';
import DynTextInput from './DynTextInput';
import DynComboInput from './DynComboInput';
import DynDateInput from './DynDateInput';

const DynamicPropertyField = (props) => {
  const { fieldData, allField, selectedValues } = props;
  const { property } = fieldData;
  const { type } = property;
  const [defDyn, setDefDyn] = useState({});

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (Object.keys(allField)) {
      const dynObj = allField.reduce((acc, row) => {
        acc[`din_${row.property.id}`] = row.property.defaultValue ?? '';
        return acc;
      }, {});
      setFieldValue('dynamicFields', dynObj);
      setDefDyn(dynObj);
    }
  }, [fieldData, selectedValues.dynamicProperties, allField, setFieldValue]);

  if (Object.keys(defDyn) && allField.length > 0) {
    if (type === PropertyType.TEXT) {
      return <DynTextInput {...props} defDyn={defDyn} type="text" />;
    } else if (type === PropertyType.NUMBER) {
      return <DynTextInput {...props} defDyn={defDyn} type="number" precision={1} />;
    } else if (type === PropertyType.DECIMAL) {
      return <DynTextInput {...props} defDyn={defDyn} type="number" precision={0.01} />;
    } else if (type === PropertyType.COMBO) {
      return <DynComboInput {...props} defDyn={defDyn} />;
    } else if (type === PropertyType.DATE) {
      return <DynDateInput {...props} defDyn={defDyn} />;
    } else {
      return <div>Hiba történt</div>;
    }
  }
};

export default DynamicPropertyField;
