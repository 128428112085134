import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'property';

const propertyService = {
  properties: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/properties`,
      data,
    });
  },

  updateProperty: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  createProperty: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  deleteProperty: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  addPropertyOptionToList: (data, propertyId) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/propertyOption/${propertyId}`,
      data,
    });
  },

  updatePropertyOption: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/propertyOption/${id}`,
      data,
    });
  },

  deletePropertyOptionFromList: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/propertyOption/${id}`,
      data,
    });
  },

  updateDefaultValue: (id, propertyId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/defaultValue/${id}/${propertyId}`,
    });
  },

  updateFormProperty: (id, formId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/formProperty/${id}/${formId}`,
    });
  },

  getPropertiesByFormId: (formId) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/formProperties/${formId}`,
    });
  },
};
export default propertyService;
