import { useCallback } from 'react';
import useUsers from '../context/UsersContext';
import appService from '../service/app.service';

const useSaveView = () => {
  const { user, updateViews } = useUsers();

  const saveView = useCallback(
    (viewName, newViewObj) => {
      const currentViews = user.views[viewName] || {};
      const updatedView = {
        ...currentViews,
        ...newViewObj,
      };
      const newViews = { ...user.views, [viewName]: updatedView };
      const viewsStringified = JSON.stringify(newViews);

      appService.updateUserView({ views: viewsStringified }, user.userId).then(() => {
        updateViews(newViews);
      });
    },
    [user, updateViews]
  );

  return saveView;
};

export default useSaveView;
