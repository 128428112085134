import React, { useEffect, useState, useMemo, useCallback } from 'react';

import biService from '../../../service/bi.service';
import { BiReportType } from '../../../config/bi_report';
import TableReport from './TableReport';
import PieReport from './PieReport';
import BarReport from './BarReport';

const BiReport = ({ reportId, reRender }) => {
  const [report, setReport] = useState({});

  const getReport = useCallback(() => {
    biService.getReport(reportId).then((data) => setReport(data));
  }, [reportId]);

  useEffect(() => {
    getReport();
  }, [reportId, reRender]);

  useEffect(() => {
    if (reRender) {
      getReport();
    }
  }, [reRender]);

  const Report = useMemo(
    () => () => {
      if (report?.reportType === BiReportType.TABLE) {
        return <TableReport report={report} />;
      } else if (report?.reportType === BiReportType.PIE) {
        return <PieReport report={report} />;
      } else if (report?.reportType === BiReportType.BAR || report?.reportType === BiReportType.HORIZONTAL) {
        return <BarReport report={report} />;
      } else {
        return <div>Hiba történt</div>;
      }
    },
    [report]
  );

  return <Report />;
};

export default BiReport;
