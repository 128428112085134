import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'project';

const projectService = {
  getProjects: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/projects`,
      data,
    });
  },

  createProject: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateProject: (data, projectId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${projectId}`,
      data,
    });
  },

  deleteProject: (projectId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${projectId}`,
    });
  },

  addContactToList: (data, id) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/projectContact/${id}`,
      data,
    });
  },

  deleteContactFromList: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/projectContact/${id}`,
      data,
    });
  },

  updateContact: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/projectContact/${id}`,
      data,
    });
  },

  addHolidayToList: (data, id) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/projectHoliday/${id}`,
      data,
    });
  },

  deleteHolidayFromList: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/projectHoliday/${id}`,
      data,
    });
  },

  updateHoliday: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/projectHoliday/${id}`,
      data,
    });
  },

  copyResources: (id, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  orderProject: (projectId, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/orderProject/${projectId}`,
      data,
    });
  },
};

export default projectService;
