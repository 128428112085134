import React, { useCallback, useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import biService from '../../../service/bi.service';

ChartJS.register(ArcElement, Tooltip, Legend, Colors);

const PieReport = ({ report }) => {
  const [chartData, setChartData] = useState({});

  const getChartData = useCallback(() => {
    biService.getReportData(report.id).then((data) => setChartData(data));
  }, [report.id]);

  useEffect(() => {
    if (Object.keys(report).length > 0) {
      getChartData();
    }
  }, [report, report?.biField, getChartData]);

  const data = {
    labels: chartData.label,
    datasets: [
      {
        label: '',
        data: chartData.data, //[12, 19, 3],
        borderWidth: 1,
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
      colors: {
        //enabled: false,
        forceOverride: true,
      },
      title: {
        display: true,
        text: report.name,
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        // itt van az onClick a soron ;)
        const label = data.labels[elements[0].index];
        //const value = data.datasets[elements.datasetIndex][elements.index];
        console.log(elements);
        console.log(label);
        console.log(data);
      }
    },
  };

  return (
    <div className="m-2 h-[650px] flex justify-center">
      <Pie data={data} options={options} />
    </div>
  );
};

export default PieReport;
