import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { AutoCompleteSelectUI, ButtonUI, DataGridUI, DebouncedInputUI, FormLabelUI } from '../../Interface';
import { dataGridColumns, generateFilter } from '../../../utils/helper';
import biService from '../../../service/bi.service';
import { BiReportTypeList } from '../../../config/bi_report';

const BiQueryTable = ({ values, dataset, refetchState }) => {
  const { refetch, setRefetch } = refetchState;
  const navigate = useNavigate();
  const { setSelectedValues } = values;
  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [{ field: 'name', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  const [filterFormState, setFilterFormState] = useState({
    name: '',
    biHeadId: '',
  });

  const getList = useCallback(() => {
    biService.getBiReports(query).then((data) => {
      setReports(data);
      setRefetch(false);
    });
  }, [query]);

  useEffect(() => {
    const conditions = {
      like: ['name'],
    };
    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: newFilter };
    });
  }, [filterFormState]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getList();
    }
  }, [query, getList]);

  useEffect(() => {
    if (refetch) {
      getList();
    }
  }, [refetch]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const columns = dataGridColumns([
    {
      field: 'name',
      headerName: 'Név',
      headerClassName: 'bg-tableHeaderColor',
      flex: 1,
    },
    {
      field: 'biHead_name',
      headerName: 'Forrás',
      headerClassName: 'bg-tableHeaderColor',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row.biHead?.name;
      },
    },
    {
      field: 'biReportType_name',
      headerName: 'Típus',
      headerClassName: 'bg-tableHeaderColor',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const findedReportType = BiReportTypeList.find((rt) => rt.value === params.row.reportType);
        return findedReportType.label;
      },
    },
  ]);

  return (
    <div className="flex  overflow-hidden m-4 ">
      <div className="h-full w-full  overflow-x-auto ">
        <div className="grid grid-cols-5 gap-x-2 mx-2">
          <div className="col-span-2">
            <DebouncedInputUI
              label="Név"
              debounceMs={500}
              setQuickFilterSearchValue={(newValue) => {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  name: newValue,
                }));
              }}
            />
          </div>
          <div className="col-span-2 ">
            <AutoCompleteSelectUI
              label={<FormLabelUI text={'Forrás'} />}
              selectedValue={filterFormState.biHeadId}
              dataset={dataset.biHeads}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    biHeadId: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    biHeadId: newVal.value,
                  }));
                }
              }}
            />
          </div>
          <div className="col-span-1 relative">
            <ButtonUI
              className="bg-primary w-100 w-full mt-2 "
              text="Új"
              type="button"
              onClick={() => {
                navigate('/app/bi/editor');
              }}
            />
          </div>
        </div>

        <div className="inline-block w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
          <DataGridUI
            initialState={query}
            rows={reports.rows}
            rowCount={reports.rowCount}
            columns={columns}
            onRowClick={(rowData) => {
              setSelectedValues(rowData.row);
            }}
            paginationMode="server"
            onPaginationModelChange={onPaginitonChange}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            name="biReport"
          />
        </div>
      </div>
    </div>
  );
};

export default BiQueryTable;
