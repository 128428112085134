import React, { useEffect, useMemo, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import huLocale from '@fullcalendar/core/locales/hu';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import useParam from '../../context/ParamContext';
import { useLoader } from '../../provider/LoaderProvider';
import { ColorPicker } from '../../config/ColorPicker';
import EventChip from './EventChip';
import useView from '../../hooks/useView';
import GanttFilter from './GanttFilter';
import employeeResourcePlannerService from '../../service/employeeResourcePlanner.service';
import { EmployeeGanttFilterType } from '../../config/gantt';
import useMenus from '../../context/MenuContext';

export const EmployeeResourcePlanner = () => {
  const { getParam, params } = useParam();
  const { menus, getMenus, getSubMenuNamesByUrls } = useMenus();
  const ganttFilter = useView('ganttFilter');
  const checkboxFiltersRef = useRef(null);
  const { showLoader, hideLoader } = useLoader();
  const [tasks, setTasks] = useState({ resources: [], taskEvents: [], backgroundEvents: [] });
  const [dateRange, setDateRange] = useState({});
  const [type, setType] = useState('');
  const calendarRef = useRef(null);

  const [timeInterval, setTimeInterval] = useState({});
  const [intervalButtons, setIntervalButtons] = useState({});
  const [calendarViews, setCalendarViews] = useState({});
  const [selectedView, setSelectedView] = useState('');

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().render();
    }
  }, [tasks.taskEvents]);

  const slotIntervals = {
    day: { days: 1 },
    month: { months: 1 },
    week: { weeks: 1 },
  };

  const calendarDayViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [{ day: 'numeric', weekday: 'short' }],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ month: 'short' }, { day: 'numeric' }],
    },
  };

  const calendarWeekViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
  };

  const calendarMonthViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ month: 'long' }],
    },
  };

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls(['projectResourcePlanner']);
  }, [menus]);

  useEffect(() => {
    if (ganttFilter && ganttFilter.selectedEmployeeValue) {
      setType(ganttFilter.selectedEmployeeValue);
    } else {
      setType(EmployeeGanttFilterType.ALL);
    }
  }, [ganttFilter]);

  useEffect(() => {
    if (type !== '' && type !== undefined) {
      getEmployeeByType();
    }
  }, [type]);

  useEffect(() => {
    const toolbarElement = document.querySelector('.fc-toolbar-chunk');
    if (toolbarElement && checkboxFiltersRef.current) {
      toolbarElement.appendChild(checkboxFiltersRef.current);
    }
  }, []);

  useEffect(() => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    setTimeInterval(slotIntervals.day);
    setCalendarViews(calendarDayViews);

    getParam();
    getMenus();
  }, []);

  useEffect(() => {
    setIntervalButtons(generateButtons(selectedView));
  }, [selectedView]);

  useEffect(() => {
    if (dateRange.projectStartDate && dateRange.projectEndDate) {
      getProjects();
    }
  }, [dateRange]);

  const getEmployeeByType = () => {
    showLoader();
    employeeResourcePlannerService
      .getEmployeeByType(type)
      .then((data) => {
        setTasks((prevState) => ({ ...prevState, resources: data }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const colorParam = params.EMPLOYEE_RESOURCE_PLANNER_CHIP_COLOR_THEME;
  const parsedParam = colorParam ? JSON.parse(colorParam) : [];

  const getProjects = () => {
    //showLoader();
    employeeResourcePlannerService
      .getProjects(dateRange)
      .then((data) => {
        const taskEvents = data.map((project) => {
          const colorStyles = getColorStyles(project.eventData, parsedParam[0], 'EMPLOYEE');

          return {
            ...project,
            ...colorStyles,
          };
        });
        setTasks((prevState) => ({ ...prevState, taskEvents }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getColorStyles = (event, colorProperties, eventType) => {
    const defaultStyles = { backgroundColor: '#fff', textColor: '#000' };

    const colorProperty = colorProperties?.[eventType];
    if (!colorProperty) return defaultStyles;

    const colorName = colorProperty.split('.').reduce((obj, key) => obj?.[key], event);
    if (!colorName) return defaultStyles;

    const colorObj = ColorPicker.find((color) => color.colorName === colorName);
    return {
      backgroundColor: colorObj?.colorBgCode || defaultStyles.backgroundColor,
      textColor: colorObj?.textColor || defaultStyles.textColor,
    };
  };

  const handleDatesSet = (dateInfo) => {
    const projectStartDate = dayjs(dateInfo.start).startOf('day');
    const projectEndDate = dayjs(dateInfo.end).endOf('day');

    if (
      !dateRange.projectStartDate ||
      !dateRange.projectEndDate ||
      !projectStartDate.isSame(dateRange.projectStartDate) ||
      !projectEndDate.isSame(dateRange.projectEndDate)
    ) {
      setDateRange({ projectStartDate, projectEndDate });
    }

    if (selectedView !== dateInfo.view.type) setSelectedView(dateInfo.view.type);
  };

  const timeIntervalButtons = {
    day: {
      text: 'Nap',
      click: () => {
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);
      },
    },
    week: {
      text: 'Hét',
      click: () => {
        setTimeInterval(slotIntervals.week);
        setCalendarViews(calendarWeekViews);
      },
    },
    month: {
      text: 'Hónap',
      click: () => {
        setTimeInterval(slotIntervals.month);
        setCalendarViews(calendarMonthViews);
      },
    },
  };

  const generateButtons = (viewName) => {
    let retVal = {};
    switch (viewName) {
      case 'resourceTimelineMonth':
        retVal = 'day,week,month';
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);

        break;
      case 'resourceTimelineYear':
        retVal = 'day,week,month';
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);

        break;
    }
    return retVal;
  };

  return (
    <div>
      <div ref={checkboxFiltersRef}>
        <GanttFilter ganttFilter={ganttFilter} type={type} setType={setType} submenuNames={submenuNames} />
      </div>
      <FullCalendar
        ref={calendarRef}
        customButtons={timeIntervalButtons}
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        initialView="resourceTimelineMonth"
        resources={tasks.resources}
        resourceOrder="visOrder"
        locales={[huLocale]}
        locale="hu"
        slotLabelFormat={[
          { month: 'long', year: 'numeric', weekday: 'long' },
          { hour: 'numeric', minute: '2-digit' },
        ]}
        datesSet={handleDatesSet}
        events={[...tasks.taskEvents, ...tasks.backgroundEvents]}
        editable={false}
        nowIndicator={true}
        eventClick={(clickInfo) => {
          if (clickInfo.event.display !== 'background') {
          }
        }}
        eventContent={(eventInfo) => {
          if (eventInfo.event.display !== 'background') {
            return <EventChip eventInfo={eventInfo} />;
          }
        }}
        headerToolbar={{
          left: `prev,next,today ${intervalButtons}`,
          center: 'title',
          right: 'resourceTimelineMonth,resourceTimelineYear',
        }}
        views={calendarViews}
        slotDuration={timeInterval}
        resourceAreaWidth="15%"
        resourceAreaHeaderContent="Erőforrás"
        scrollTimeReset={false}
        resourceLabelClassNames={(data) => {
          if (!data.resource._resource.parentId) {
            return ['fc-res-group'];
          }
        }}
      />
    </div>
  );
};
