import RoleList from './RoleList';

export default function RoleIndex() {
  return (
    <main className="px-2 py-6 sm:px-6 lg:px-8 bg-gray-100">
      <p className="text-md font-bold tracking-tight text-gray-700 italic sm:text-3xl text-center">
        Jogosultság kezelése
      </p>
      <RoleList />
    </main>
  );
}
