import React from 'react';

import DynamicPropertyField from './DynamicPropertyField';

const DynamicPropertyTable = ({ rows, disabled, selectedValues }) => {
  return (
    <div className="max-h-80 overflow-y-scroll overflow-x-hidden">
      <table className="border w-full">
        <thead className="bg-tableHeaderColor">
          <tr className="grid grid-cols-4">
            <th className="border p-3 col-span-2">Megnevezés</th>
            <th className="border p-3 col-span-2">Érték</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={row.property.id} className={index % 2 === 0 ? 'bg-gray-100 grid grid-cols-4' : 'grid grid-cols-4'}>
              <td className="border py-1 col-span-2">{row.property.name}</td>
              <td className="border py-1 col-span-2 relative">
                <div className="absolute bottom-0 w-full">
                  <DynamicPropertyField
                    fieldData={row}
                    allField={rows}
                    name={`dynamicFields.din_${row.property.id}`}
                    disabled={disabled}
                    selectedValues={selectedValues}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DynamicPropertyTable;
