import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, IconButton } from '@mui/material';
import useSaveView from '../../hooks/useSaveView';
import { AutoCompleteSelectUI } from '../Interface';
import AddIcon from '@mui/icons-material/Add';

const GanttFilter = ({ ganttFilter, filter, setSimpleTaskDialogOpen }) => {
  const saveView = useSaveView();
  const { filterFormState, setFilterFormState } = filter;
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    const newViewSettings = { ...ganttFilter, [name]: checked };
    saveView('ganttFilter', newViewSettings);
  };

  return (
    <FormGroup>
      <div className="grid grid-cols-5 items-center">
        <FormControlLabel
          className="text-white"
          control={
            <Checkbox
              name="production"
              checked={ganttFilter?.production || false}
              onChange={handleCheckboxChange}
              sx={{
                color: '#fff',
                '&.Mui-checked': {
                  color: '#fff',
                },
              }}
            />
          }
          label="Gyártás"
        />
        <FormControlLabel
          className="text-white text-lg"
          control={
            <Checkbox
              name="task"
              checked={ganttFilter?.task || false}
              onChange={handleCheckboxChange}
              sx={{
                color: '#fff',
                '&.Mui-checked': {
                  color: '#fff',
                },
              }}
            />
          }
          label="Feladatok"
        />
        <AutoCompleteSelectUI
          className="ganttfilter relative -top-2 -left-2"
          table="production"
          label={<span className="text-white text-lg">Munkaszám</span>}
          listType={{ id: 'productionId', name: 'workingNumber' }}
          selectedValue={filterFormState.productionId}
          onChange={(_e, newVal, reason) => {
            if (reason === 'clear') {
              setFilterFormState((prevState) => ({
                ...prevState,
                productionId: '',
              }));
            } else {
              setFilterFormState((prevState) => ({
                ...prevState,
                productionId: newVal.value,
              }));
            }
          }}
          conditions={[`production.archive = 'N'`]}
        />
        <AutoCompleteSelectUI
          className="ganttfilter relative -top-2 left-5"
          label={<span className="text-white text-lg">Művelet</span>}
          table="operation"
          listType={{ id: 'operationId', name: 'operationName' }}
          selectedValue={filterFormState.operationId}
          onChange={(_e, newVal, reason) => {
            if (reason === 'clear') {
              setFilterFormState((prevState) => ({
                ...prevState,
                operationId: '',
              }));
            } else {
              setFilterFormState((prevState) => ({
                ...prevState,
                operationId: newVal.value,
              }));
            }
          }}
          conditions={[`operation.archive = 'N'`]}
        />

        <IconButton size="small" onClick={() => setSimpleTaskDialogOpen(true)}>
          <div className="bg-white text-black text-sm font-medium relative left-12  py-2 px-5 rounded flex items-center justify-center gap-1">
            <AddIcon className="text-xl" />
            <span className="text-base">Feladat</span>
          </div>
        </IconButton>
      </div>
    </FormGroup>
  );
};

export default GanttFilter;
