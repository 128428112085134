import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { GridRowModes, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';
import { Field, Form, Formik } from 'formik';
import dayjs from 'dayjs';

import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import { dataGridColumns } from '../../utils/helper';
import Yup from '../../utils/yup';
import innovationService from '../../service/innovation.service';
import { DataGridUI, ButtonUI, FormErrorUI, InputUI } from '../Interface';

function EditToolbar(props) {
  const { setRows, setRowModesModel, rows, user, rowModesModel, setCurrentRow, id } = props;

  const handleClick = () => {
    const newRow = {
      communicationId: Date.now(),
      message: '',
      userId: user?.userId,
      userName: user?.userName,
      isNew: true,
    };

    setRows((oldRows) => [newRow, ...oldRows]);
    setCurrentRow(newRow);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newRow.communicationId]: { mode: GridRowModes.Edit, fieldToFocus: 'id' },
    }));
  };

  const isNewRow = rows.some(
    (row) => row.isNew || Object.values(rowModesModel).some((row) => row.mode === GridRowModes.Edit)
  );

  return (
    <GridToolbarContainer>
      <ButtonUI
        className="bg-success mb-1"
        text="Kommunikáció hozzáadása"
        disabled={isNewRow || !id}
        onClick={handleClick}
      />
    </GridToolbarContainer>
  );
}

const FormTable = ({ communicationList, setCommunicationList, id, setRefetchTable, user }) => {
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [currentRow, setCurrentRow] = useState({});

  useEffect(() => {
    setRows(communicationList);
  }, [communicationList]);

  const validationSchema = Yup.object().shape({
    message: Yup.string().required(),
  });

  const handleAddRow = (updatedRow) => {
    const insertObject = {
      ...updatedRow,
      createdBy: user?.userId,
    };
    innovationService.addCommunicationToList(insertObject, id).then((data) => {
      addNotification({
        content: 'Sikeres hozzáadás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setCommunicationList((prev) => {
        return [...prev, data];
      });
    });
  };

  const handleUpdateRow = (updatedRow, communicationId) => {
    const updData = {
      ...updatedRow,
      updatedBy: user?.userId,
    };
    innovationService.updateCommunication(updData, communicationId).then(() => {
      addNotification({
        content: 'Sikeres mentés!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
    });
  };

  const handleDeleteRow = (communicationId) => {
    const deleteData = {
      deletedBy: user.userId,
      deletedAt: dayjs(new Date()),
    };
    innovationService.deleteCommunicationFromList(deleteData, communicationId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
    });
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    const rowToEdit = rows.find((row) => row.communicationId === id);
    setCurrentRow(rowToEdit);
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (communicationId) => () => {
    setRows(rows.filter((row) => row.communicationId !== communicationId));
    handleDeleteRow(communicationId);
  };

  const handleCancelClick = (id) => () => {
    const editedRow = rows.find((row) => row.communicationId === id);

    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.communicationId !== id));
    } else {
      setRows((prevRows) => prevRows.map((row) => (row.communicationId === id ? { ...editedRow, isNew: false } : row)));
    }
    setCurrentRow(null);
  };

  const processRowUpdate = async (newRow) => {
    await validationSchema.validate(newRow, { abortEarly: false });
    const updatedRow = { ...newRow, isNew: newRow.isNew ?? false };
    const updatedRows = rows.map((row) => (row.communicationId === newRow.communicationId ? updatedRow : row));

    updatedRows.sort((a, b) => a.communicationId - b.communicationId);

    setRows(updatedRows);

    if (updatedRow.isNew) {
      handleAddRow(updatedRow);
    } else {
      handleUpdateRow(updatedRow, newRow.communicationId);
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = (setFieldValue, validateField, errors) => {
    return dataGridColumns([
      {
        field: 'createdAt',
        headerName: 'Dátum.',
        flex: 0.5,
        valueGetter: (params) => (params?.row.isNew ? '' : dayjs(params.row.createdAt).format('YYYY-MM-DD HH:mm:ss')),
      },
      {
        field: 'userId',
        headerName: 'Felhasználó',
        flex: 0.5,
        renderCell: (params) => {
          return params?.row.isNew ? params.row?.userName : params.row.user?.userName;
        },
      },
      {
        field: 'message',
        headerName: 'Üzenet',
        flex: 1,
        editable: true,
        renderEditCell: (params) => (
          <Field
            name="message"
            as={InputUI}
            helperText={<FormErrorUI message={errors.message} />}
            variant="standard"
            onChange={(e) => {
              setFieldValue('message', e.target.value ?? '').then(() => {
                validateField('message');
              });
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value,
              });
            }}
          />
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        flex: 0.25,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          const isAnyRowInEditMode = Object.values(rowModesModel).some((row) => row.mode === GridRowModes.Edit);

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              disabled={isAnyRowInEditMode}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ]);
  };

  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <Formik
            initialValues={currentRow}
            validationSchema={validationSchema}
            onSubmit={processRowUpdate}
            validateOnMount
            enableReinitialize
          >
            {({ errors, setFieldValue, validateField }) => (
              <Form className="w-full">
                <DataGridUI
                  sx={{ height: 300 }}
                  columns={columns(setFieldValue, validateField, errors)}
                  rows={rows}
                  getRowId={(row) => row.communicationId}
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processRowUpdate}
                  slots={{
                    toolbar: EditToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      setRows,
                      setRowModesModel,
                      rows,
                      rowModesModel,
                      user,
                      setCurrentRow,
                      id,
                    },
                  }}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default FormTable;
