import React from 'react';
import { FormGroup } from '@mui/material';
import useSaveView from '../../hooks/useSaveView';
import { ButtonUI, SelectUI } from '../Interface';
import { EmployeeGanttOptionType } from '../../config/gantt';
import usePages from '../../context/PageContext';
import { useNavigate } from 'react-router-dom';

const GanttFilter = ({ type, setType, submenuNames }) => {
  const navigate = useNavigate();
  const saveView = useSaveView();
  const { setPages } = usePages();

  const navigateToProjectResourcePlannerPage = () => {
    setPages({ subMenuName: submenuNames[0] });
    navigate('/app/projectResourcePlanner');
  };

  return (
    <FormGroup>
      <div className="flex items-center gap-5">
        <div className="w-[200px]">
          <SelectUI
            label={<span className="text-white text-lg">Szűrés</span>}
            option={EmployeeGanttOptionType}
            value={type}
            inputProps={{
              sx: {
                color: 'white',
              },
            }}
            onChange={(e) => {
              const value = e.target.value;
              setType(value);
              saveView('ganttFilter', { selectedEmployeeValue: value });
            }}
          />
        </div>
        <ButtonUI
          text={submenuNames[0]}
          className="bg-white text-black"
          onClick={navigateToProjectResourcePlannerPage}
        />
      </div>
    </FormGroup>
  );
};

export default GanttFilter;
