import { useEffect, useState } from 'react';
import propertyService from '../../service/property.service';
import DynamicPropertyTable from './DynamicPropertyTable';

const DynamicPropertyMain = ({ submenuId, disabled, selectedValues }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (submenuId > 0) {
      getFormProperties();
    }
  }, [submenuId]);

  const getFormProperties = () => {
    propertyService.getPropertiesByFormId(submenuId).then((data) => {
      setRows(data);
    });
  };

  return <DynamicPropertyTable rows={rows} disabled={disabled} selectedValues={selectedValues} />;
};

export default DynamicPropertyMain;
