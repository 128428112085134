import { useState, useEffect } from 'react';

import companyService from '../../service/company.service';
import { dataGridColumns, generateFilter } from '../../utils/helper';

import { DataGridUI, FormLabelUI, DebouncedInputUI } from '../Interface';

export default function CompanyReports({ refetch, values, viewField, operatorsDisabled }) {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  const [filterFormState, setFilterFormState] = useState({
    companyName: '',
    adress: '',
  });

  const conditions = {
    like: ['companyName'],
  };

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'archive',
        value: 'N',
      },
    ];

    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
  }, [filterFormState]);

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getList();
    }
  }, [query]);
  useEffect(() => {
    if (refetchTable && query.filter?.length > 0) {
      getList();
    }
  }, [refetchTable]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const getList = () => {
    companyService.getCompanies(query).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const columns = dataGridColumns([
    {
      field: 'companyName',
      headerName: 'Cégnév',
      headerClassName: 'bg-tableHeaderColor',
      flex: 1,
    },
    {
      field: 'fulladdress',
      headerName: 'Levelezési cím',
      headerClassName: 'bg-tableHeaderColor',
      flex: 1,
      renderCell: (params) => {
        const { postCode, city, street } = params.row;
        if (postCode === null || city === null || street === null) {
          return 'Nincs adat.';
        } else {
          return `${postCode}, ${city}, ${street}`;
        }
      },
    },
    {
      field: 'fullbilladdress',
      headerName: 'Számlázási cím',
      headerClassName: 'bg-tableHeaderColor',
      flex: 1,
      renderCell: (params) => {
        const { billPostcode, billCity, billAddress } = params.row;
        if (billPostcode === null || billCity === null || billAddress === null) {
          return 'Nincs adat.';
        } else {
          return `${billPostcode}, ${billCity}, ${billAddress}`;
        }
      },
    },
  ]);

  return (
    <>
      <div className="col-span-4 flex flex-col overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="col h-full flex-col overflow-hidden bg-white p-2 shadow sm:rounded-lg">
          <div className="flex h-full overflow-hidden border-t border-gray-200">
            <div className="w-full overflow-x-auto xxl:overflow-x-hidden">
              <div className="sticky top-0 bg-white">
                <div className="pt-2 ">
                  <div className="grid grid-cols-4 gap-x-4 mx-4">
                    <div className="col-span-2 md:col-span-1">
                      <DebouncedInputUI
                        label={<FormLabelUI text="Cég neve" />}
                        debounceMs={800}
                        setQuickFilterSearchValue={(newValue) => {
                          setFilterFormState((prevState) => ({
                            ...prevState,
                            companyName: newValue,
                          }));
                        }}
                        quickFilterSearchValue={filterFormState.companyName}
                      />
                    </div>
                    <div className="col-span-2 md:col-span-1">
                      <DebouncedInputUI
                        label={<FormLabelUI text="Levelezési cím" />}
                        debounceMs={800}
                        setQuickFilterSearchValue={(newValue) => {
                          setFilterFormState((prevState) => ({
                            ...prevState,
                            adress: newValue,
                          }));
                        }}
                        quickFilterSearchValue={filterFormState.adress}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="inline-block min-w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
                <div className="z-40 min-w-full h-[100%]">
                  <DataGridUI
                    initialState={query}
                    rows={reports.rows}
                    rowCount={reports.rowCount}
                    columns={columns}
                    onRowClick={(rowData) => {
                      if (!viewField) return;
                      setSelectedValues(rowData.row);
                    }}
                    paginationMode="server"
                    onPaginationModelChange={onPaginitonChange}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    disableRowSelectionOnClick={!viewField}
                    getRowId={(row) => row.companyId}
                    name={'Company'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
