import React, { useEffect, useMemo, useCallback } from 'react';
import { Field } from 'formik';
import { useFormikContext } from 'formik';

import { SelectUI } from '../Interface';
import { getDinVal, getIndex } from '../../utils/DynHelper';
import useSetDynField from '../../hooks/useSetDynField';

const DynComboInput = ({ name, disabled, fieldData, selectedValues, defDyn }) => {
  const { property } = fieldData;
  const { propertyOption } = property;
  const { values } = useFormikContext();
  const setVal = useSetDynField();

  const formattedOptions = useMemo(
    () =>
      propertyOption.map((option) => ({
        label: option.name,
        value: option.id,
      })),
    [propertyOption]
  );

  const dynVal = useMemo(
    () => getDinVal(selectedValues.dynamicProperties, name),
    [selectedValues.dynamicProperties, name]
  );

  useEffect(() => {
    const setterVal = dynVal?.comboBoxVal ?? defDyn?.[getIndex(name)] ?? '';
    setVal(name, setterVal);
  }, [dynVal, name, defDyn, setVal]);

  const handleChange = useCallback(
    (e) => {
      setVal(name, e.target.value);
    },
    [name, setVal]
  );
  return (
    <Field
      name={`${name}`}
      type={'select'}
      as={SelectUI}
      fullWidth
      value={values?.dynamicFields?.[getIndex(name)] ?? ''}
      variant="standard"
      disabled={disabled}
      option={formattedOptions}
      onChange={handleChange}
    />
  );
};

export default DynComboInput;
