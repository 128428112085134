import React, { useMemo, useEffect, useCallback } from 'react';
import { Field, useFormikContext } from 'formik';
import dayjs from 'dayjs';

import { getDinVal, getIndex } from '../../utils/DynHelper';
import useSetDynField from '../../hooks/useSetDynField';
import { DatePickerUI } from '../Interface';

const DynDateInput = ({ name, disabled, selectedValues, defDyn }, ...props) => {
  const { values, setFieldValue } = useFormikContext();
  const setVal = useSetDynField();

  const dynVal = useMemo(
    () => getDinVal(selectedValues.dynamicProperties, name),
    [selectedValues.dynamicProperties, name]
  );

  useEffect(() => {
    const setterVal = dynVal?.dateVal ?? defDyn?.[getIndex(name)] ?? null;

    setVal(name, setterVal, setFieldValue, values);
  }, [dynVal, name, values, defDyn, setFieldValue, setVal]);

  const handleChange = useCallback(
    (e) => {
      setVal(name, dayjs(e).toISOString(), setFieldValue, values);
    },
    [name, setFieldValue, values, setVal]
  );

  return (
    <Field
      name={name}
      as={DatePickerUI}
      fullWidth
      variant="standard"
      disabled={disabled}
      value={dayjs(values.dynamicFields?.[getIndex(name)])}
      onChange={handleChange}
      {...props}
    />
  );
};

export default DynDateInput;
