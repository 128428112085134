import React, { useEffect, useMemo, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import huLocale from '@fullcalendar/core/locales/hu';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import useParam from '../../context/ParamContext';
import { useLoader } from '../../provider/LoaderProvider';
import useView from '../../hooks/useView';
import GanttFilter from './GanttFilter';
import { GanttType } from '../../config/gantt';
import projectResourcePlannerService from '../../service/projectResourcePlanner.service';
import useMenus from '../../context/MenuContext';
import { ColorPicker } from '../../config/ColorPicker';
import addNotification from '../../utils/addNotification';
import { generateFilter } from '../../utils/helper';
import { NotificationType } from '../../config';
import simpletaskService from '../../service/simpletask.service';
import useSaveView from '../../hooks/useSaveView';
import SimpleTaskDialog from './SimpleTaskDialog';
import { StatusDialog } from './StatusDialog';
import EventChip from './EventChip';

export const ProjectResourcePlanner = () => {
  const { getParam, params } = useParam();
  const { menus, getMenus, getSubMenuNamesByUrls, getMenuSubMenuId } = useMenus();
  const ganttFilter = useView('ganttFilter');
  const saveView = useSaveView();
  const { showLoader, hideLoader } = useLoader();
  const [tasks, setTasks] = useState({ resources: [], taskEvents: [], backgroundEvents: [] });
  const [type, setType] = useState('');
  const [dateRange, setDateRange] = useState({});
  const filtersRef = useRef();
  const calendarRef = useRef(null);
  const [task, setTask] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [taskChanged, setTaskChanged] = useState(false);
  const [submenu, setSubmenu] = useState(null);
  const [simpleTaskDialogOpen, setSimpleTaskDialogOpen] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [expandedResources, setExpandedResources] = useState(true);
  const [filterVal, setFilterVal] = useState({
    statusId: [],
    resourceId: '',
  });
  const [query, setQuery] = useState({
    filter: [],
  });

  const [timeInterval, setTimeInterval] = useState({});
  const [intervalButtons, setIntervalButtons] = useState({});
  const [calendarViews, setCalendarViews] = useState({});
  const [selectedView, setSelectedView] = useState('');
  const [copiedEvent, setCopiedEvent] = useState(null);
  const [showWorkhour, setShowWorkHour] = useState(true);
  const [disappearedWorkHour, setDisappearedWorkHour] = useState(true);

  const slotIntervals = {
    halfHour: { hours: 0.5 },
    hour: { hours: 1 },
    day: { days: 1 },
    week: { weeks: 1 },
    month: { months: 1 },
  };

  const calendarHourViews = {
    resourceTimelineDay: {
      slotLabelFormat: [{ hour: 'numeric', minute: '2-digit' }],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [
        { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' },
        { hour: 'numeric', minute: '2-digit' },
      ],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [
        { day: 'numeric', weekday: 'long' },
        { hour: 'numeric', minute: '2-digit' },
      ],
    },
  };

  const calendarDayViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [{ day: 'numeric', weekday: 'long' }],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [{ day: 'numeric', weekday: 'short' }],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ month: 'short' }, { day: 'numeric' }],
    },
  };

  const calendarWeekViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
  };

  const calendarMonthViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ month: 'long' }],
    },
  };

  const conditions = {};

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'archive',
        value: 'N',
      },
    ];
    const newStatus = filterVal.statusId.map((sIds) => sIds.value);
    const newFilterVal = { ...filterVal, statusId: newStatus };
    const newFilter = generateFilter(newFilterVal, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
  }, [filterVal]);

  useEffect(() => {
    if (Object.keys(query).length > 0 && query.filter?.length > 0) {
      getProjectResourcesByType();
    }
  }, [query]);

  useEffect(() => {
    setSubmenu(getMenuSubMenuId('Project'));
  }, [menus]);

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls([
      'resourceOne',
      'resourceTwo',
      'resourceThree',
      'resourceFour',
      'machine',
      'employeeResourcePlanner',
    ]);
  }, [menus]);

  useEffect(() => {
    const toolbarElement = document.querySelector('.fc-toolbar-chunk');
    if (toolbarElement && filtersRef.current) {
      toolbarElement.appendChild(filtersRef.current);
    }
  }, []);

  useEffect(() => {
    if (ganttFilter?.selectedView && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(ganttFilter.selectedView);
    }
  }, [ganttFilter?.selectedView]);

  useEffect(() => {
    if (ganttFilter && ganttFilter.selectedResource) {
      setType(ganttFilter.selectedResource);
    } else {
      setType(GanttType.RESOURCE_ONE);
    }
  }, [ganttFilter]);

  useEffect(() => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    setTimeInterval(slotIntervals.halfHour);
    setCalendarViews(calendarHourViews);

    // @TODO promiseArray
    getParam();
    getMenus();
  }, []);

  useEffect(() => {
    if (taskChanged) {
      getTasks();
      setTaskChanged(false);
    }
  }, [taskChanged]);

  useEffect(() => {
    setIntervalButtons(generateButtons(selectedView));
  }, [selectedView]);

  useEffect(() => {
    if (ganttFilter?.selectedView && timeInterval) {
      setSelectedView(ganttFilter.selectedView);

      const getSlotLabelFormat = () => {
        const view = ganttFilter.selectedView;

        if (timeInterval.hours === 0.5) {
          return calendarHourViews[view]?.slotLabelFormat || [];
        } else if (timeInterval.hours === 1) {
          return calendarHourViews[view]?.slotLabelFormat || [];
        } else if (timeInterval.days === 1) {
          return calendarDayViews[view]?.slotLabelFormat || [];
        } else if (timeInterval.weeks === 1) {
          return calendarWeekViews[view]?.slotLabelFormat || [];
        } else if (timeInterval.months === 1) {
          return calendarMonthViews[view]?.slotLabelFormat || [];
        } else {
          return [];
        }
      };

      if (timeInterval.days === 1) {
        setDisappearedWorkHour(false);
      } else {
        setDisappearedWorkHour(true);
        setShowWorkHour(false);
      }

      setCalendarViews((prevViews) => {
        const newViewConfig = {
          slotLabelFormat: getSlotLabelFormat(),
        };

        return {
          ...prevViews,
          [ganttFilter.selectedView]: {
            ...prevViews[ganttFilter.selectedView],
            ...newViewConfig,
          },
        };
      });
    }
  }, [ganttFilter?.selectedView, timeInterval]);

  useEffect(() => {
    if (ganttFilter.timeInterval) {
      setTimeInterval(ganttFilter.timeInterval);
    }
  }, [ganttFilter.timeInterval, selectedView]);

  useEffect(() => {
    if (ganttFilter.status && ganttFilter.status !== filterVal.statusId) {
      setFilterVal((prev) => ({ ...prev, statusId: ganttFilter.status }));
    }
  }, [ganttFilter.status]);

  useEffect(() => {
    if (type !== '' && type !== undefined) {
      if (type !== GanttType.RESOURCE_ONE) {
        setFilterVal((prevState) => ({
          ...prevState,
          resourceId: '',
        }));
      } else {
        getProjectResourcesByType();
      }

      if (type === GanttType.RESOURCE_ONE) {
        setDisappearedWorkHour(false);
      } else {
        setDisappearedWorkHour(true);
        setShowWorkHour(false);
      }
    }
  }, [type]);

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate && type !== '' && tasks.resources.length > 0) {
      getTasks();
      if (showWorkhour) {
        getEstimatedTimes();
      } else {
        setTasks((prev) => ({ ...prev, backgroundEvents: [] }));
      }
    }
  }, [dateRange, type, tasks.resources, showWorkhour]);

  const colorParam = params.PROJECT_RESOURCE_PLANNER_CHIP_COLOR_THEME;
  const parsedParam = colorParam ? JSON.parse(colorParam) : [];

  const getTasks = async () => {
    const normalTasks = await projectResourcePlannerService.getTasksByType(type, dateRange);

    const taskEvents = normalTasks.map((task) => {
      const colorStyles = getColorStyles(task.eventData, parsedParam[0], 'PROJECT');

      return {
        ...task,
        ...colorStyles,
      };
    });

    setTasks((prevState) => ({ ...prevState, taskEvents }));
  };

  const getEstimatedTimes = async () => {
    const estimatedTimes = await projectResourcePlannerService.getWorkHours(dateRange);
    generateWorkHourEvents(estimatedTimes);
  };

  const getProjectResourcesByType = () => {
    showLoader();
    projectResourcePlannerService
      .getProjectResourcesByType(type, query)
      .then((data) => {
        setTasks((prevState) => ({ ...prevState, resources: data }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const generateWorkHourEvents = (estimatedTimes) => {
    const datesArray = [];

    let currentDate = dateRange.startDate;
    while (currentDate.isBefore(dateRange.endDate) || currentDate.isSame(dateRange.endDate)) {
      datesArray.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'day');
    }

    const workHourEvents = [];

    for (const res of tasks.resources) {
      for (const child of res.children) {
        const resourceId = parseInt(child.id.substring(1));

        for (const date of datesArray) {
          const findedTime = estimatedTimes.find(
            (et) => dayjs(et.date).isSame(date, 'day') && et.projectResourceId === resourceId
          );

          workHourEvents.push({
            title: findedTime?.timeSpent ?? 0,
            start: dayjs(date).startOf('day').toISOString(),
            end: dayjs(date).endOf('day').toISOString(),
            resourceId: `M${resourceId}`,
            display: 'background',
            eventData: { elapsedHour: true },
          });
        }
      }
    }

    setTasks((prev) => ({ ...prev, backgroundEvents: workHourEvents }));

    const unknownProject = !!estimatedTimes.find((et) => !!!et.projectResourceId);
    if (unknownProject) {
      addNotification({
        content: 'Nem minden időadat jeleníthető meg! A dolgozó nincs hozzáadva a projekthez!',
        type: NotificationType.ERROR,
      });
    }
  };

  const getTaskById = (simpleTaskId) => {
    showLoader();
    simpletaskService
      .getTaskById(simpleTaskId)
      .then((data) => {
        setTask(data);
        setDialogOpen(true);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getColorStyles = (event, colorProperties, eventType) => {
    const defaultStyles = { backgroundColor: '#fff', textColor: '#000' };

    const colorProperty = colorProperties?.[eventType];
    if (!colorProperty) return defaultStyles;

    const colorName = colorProperty.split('.').reduce((obj, key) => obj?.[key], event);
    if (!colorName) return defaultStyles;

    const colorObj = ColorPicker.find((color) => color.colorName === colorName);
    return {
      backgroundColor: colorObj?.colorBgCode || defaultStyles.backgroundColor,
      textColor: colorObj?.textColor || defaultStyles.textColor,
    };
  };

  const updateEvent = (info) => {
    const { event, revert } = info;

    const resource = event.getResources()[0].id;
    const resourceId = parseInt(resource.substring(1));

    const isResource = resource.startsWith('M');

    if (!isResource) {
      addNotification({
        content: 'Az esemény nem helyezhető csoportra!',
        type: NotificationType.ERROR,
      });
      revert();
      return;
    }

    const projectResources = event._def.extendedProps.eventData.project;

    const durationInMinutes = dayjs(event.end).diff(dayjs(event.start), 'minute');
    const duration = durationInMinutes / 60;
    const startDate = dayjs(event.start).toISOString();
    const endDate = dayjs(event.end).toISOString();

    const updatedEventData = {
      startDate,
      endDate,
      duration,
    };

    let resourceUpdated = false;

    Object.entries(projectResources).forEach(([key, resourceArray]) => {
      if (key.startsWith('projectResource')) {
        const matchedResource = resourceArray.find((res) => res.listId === resourceId);
        if (matchedResource) {
          const newKey = key.replace('projectResource', 'resource') + 'Id';
          updatedEventData[newKey === 'resourceFiveId' ? 'machineId' : newKey] = matchedResource.resourceId;
          resourceUpdated = true;
        }
      }
    });

    if (!resourceUpdated) {
      addNotification({
        content: 'A feladatok csak a saját projektjükön belül mozgathatóak!',
        type: NotificationType.WARNING,
      });
      revert();
      return;
    }

    const simpleTaskId = event.id;

    projectResourcePlannerService
      .updateTask(updatedEventData, simpleTaskId)
      .then(() => {
        addNotification({
          content: 'Sikeres módosítás!',
          type: NotificationType.SUCCESS,
        });
        getTasks();
      })
      .catch(() => {
        revert();
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleDatesSet = (dateInfo) => {
    const startDate = dayjs(dateInfo.start).startOf('day');
    const endDate = dayjs(dateInfo.end).endOf('day');

    if (
      !dateRange.startDate ||
      !dateRange.endDate ||
      !startDate.isSame(dateRange.startDate) ||
      !endDate.isSame(dateRange.endDate)
    ) {
      setDateRange({ startDate, endDate });
    }

    if (selectedView !== dateInfo.view.type) {
      setSelectedView(dateInfo.view.type);

      if (Object.keys(ganttFilter).length > 0) {
        //@TODO ez itt az összes ganttnak a selectedviewját felülveri
        saveView('ganttFilter', { selectedView: dateInfo.view.type });
      } else {
        return;
      }
    }
  };

  const timeIntervalButtons = {
    halfHour: {
      text: 'Fél óra',
      click: () => {
        setTimeInterval(slotIntervals.halfHour);
        setCalendarViews(calendarHourViews);
        saveView('ganttFilter', { timeInterval: slotIntervals.halfHour });
      },
    },
    hour: {
      text: 'Óra',
      click: () => {
        setTimeInterval(slotIntervals.hour);
        setCalendarViews(calendarHourViews);
        saveView('ganttFilter', { timeInterval: slotIntervals.hour });
      },
    },
    day: {
      text: 'Nap',
      click: () => {
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);
        saveView('ganttFilter', { timeInterval: slotIntervals.day });
      },
    },
    week: {
      text: 'Hét',
      click: () => {
        setTimeInterval(slotIntervals.week);
        setCalendarViews(calendarWeekViews);
        saveView('ganttFilter', { timeInterval: slotIntervals.week });
      },
    },
    month: {
      text: 'Hónap',
      click: () => {
        setTimeInterval(slotIntervals.month);
        setCalendarViews(calendarMonthViews);
        saveView('ganttFilter', { timeInterval: slotIntervals.month });
      },
    },
  };

  const generateButtons = (viewName) => {
    let retVal = {};
    switch (viewName) {
      case 'resourceTimelineDay':
        retVal = 'halfHour,hour,day';
        setTimeInterval(slotIntervals.halfHour);
        setCalendarViews(calendarHourViews);

        break;
      case 'resourceTimelineWeek':
        retVal = 'halfHour,hour,day,week';
        setTimeInterval(slotIntervals.halfHour);
        setCalendarViews(calendarHourViews);

        break;
      case 'resourceTimelineMonth':
        retVal = 'hour,day,week,month';
        setTimeInterval(slotIntervals.hour);
        setCalendarViews(calendarHourViews);

        break;
      case 'resourceTimelineYear':
        retVal = 'day,week,month';
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);

        break;
    }
    return retVal;
  };

  const indexToWordMap = {
    0: 'One',
    1: 'Two',
    2: 'Three',
    3: 'Four',
    4: 'Five',
  };

  const handleDateClick = (info) => {
    if (copiedEvent) {
      const resource = info.resource.id;
      const projectResources = copiedEvent.extendedProps.eventData.project;
      const resourceId = parseInt(resource.substring(1));

      let matchedResources = [];
      Object.entries(projectResources).forEach(([key, resourceArray]) => {
        if (key.startsWith('projectResource')) {
          const matchedResource = resourceArray.find((res) => res.listId === resourceId);
          if (matchedResource) {
            matchedResources.push({ resourceId: matchedResource.resourceId, key });
          }
        }
      });

      if (matchedResources.length === 0) {
        addNotification({
          content: 'Nem található megfelelő erőforrás!',
          type: NotificationType.ERROR,
        });
        return;
      }

      let newEvent = {
        ...copiedEvent.extendedProps.eventData,
        simpleTaskName: `${copiedEvent.title}`,
        startDate: dayjs(info.date).toISOString(),
        endDate: dayjs(info.date).add(dayjs(copiedEvent.end).diff(copiedEvent.start, 'minute'), 'minute').toISOString(),
      };

      Object.keys(newEvent).forEach((key) => {
        if (key.startsWith('resource') && key.endsWith('Id')) {
          delete newEvent[key];
        }
      });

      matchedResources.forEach(({ resourceId, key }) => {
        const resourceIndex = Object.keys(indexToWordMap).find((keyIndex) => key.endsWith(indexToWordMap[keyIndex]));
        const resourceKey = `resource${indexToWordMap[resourceIndex]}Id`;
        newEvent[resourceKey] = resourceId;
      });

      showLoader();

      simpletaskService
        .createSimpleTask(newEvent)
        .then((createdTask) => {
          setTasks((prevState) => ({
            ...prevState,
            taskEvents: [
              ...prevState.taskEvents,
              {
                ...newEvent,
                id: createdTask.simpleTaskId,
                title: newEvent.simpleTaskName,
                resourceId: resourceId,
                backgroundColor: copiedEvent.backgroundColor,
                textColor: copiedEvent.textColor,
              },
            ],
          }));
          setCopiedEvent(null);
        })
        .finally(() => {
          hideLoader();
          getTasks();
        });
    }
  };

  const projectInterval = tasks.resources.flatMap((resource) => {
    const today = dayjs();

    const startDate = dayjs(resource.startDate);
    const endDate = dayjs(resource.endDate);

    const startDaysDifference = startDate.diff(today, 'day');
    const endDaysDifference = endDate.diff(today, 'day');

    let backgroundColor = '#bbf7d0';

    if (startDaysDifference <= 7 && startDaysDifference >= 0) {
      backgroundColor = '#1185f2';
    } else if (endDaysDifference <= 21 && endDaysDifference > 14) {
      backgroundColor = '#fdba74';
    } else if (endDaysDifference <= 14 && endDaysDifference >= 0) {
      backgroundColor = '#f87171';
    }

    const intervals = [];

    intervals.push({
      start: dayjs(resource.startDate).startOf('day').toISOString(),
      end: dayjs(resource.endDate).add(1, 'day').startOf('day').toISOString(),
      resourceId: resource.id,
      display: 'background',
      backgroundColor: backgroundColor,
    });

    resource.holidays.forEach((holiday) => {
      const holidayStart = dayjs(holiday.startDate).startOf('day');
      const holidayEnd = dayjs(holiday.endDate).add(1, 'day').startOf('day');

      if (startDate.isBefore(holidayEnd) && endDate.isAfter(holidayStart)) {
        intervals.push({
          start: holidayStart.toISOString(),
          end: holidayEnd.toISOString(),
          resourceId: resource.id,
          display: 'background',
          backgroundColor: '#fde68a',
        });
      }
    });

    return intervals;
  });

  const toggleResourceGroup = () => {
    setExpandedResources((prev) => !prev);
  };

  const resourceColumns = [
    {
      field: '',
      headerClassNames: 'hide',
      headerContent: 'Projekt',
    },
    {
      field: 'id',
      headerContent: '',
      headerClassNames: 'hide',
      cellContent: function (_arg) {
        return '';
      },
      cellClassNames: function (arg) {
        //const extendedProps = arg.resource.extendedProps;
        const isParent = !arg.resource._resource.parentId;
        const colorClasses = ['bgBlue', 'bgRed', 'bgYellow', 'bgPurple', 'bgCyan'];
        const classNames = ['extraCol'];

        if (isParent) {
          classNames.push(colorClasses[Math.floor(Math.random() * colorClasses.length)]);
        } else {
          //classNames.push('bgWhite');
        }

        return classNames;
      },
    },
    {
      field: 'id',
      headerContent: '',
      headerClassNames: 'hide',
      cellContent: function (_arg) {
        return '';
      },
      cellClassNames: function (arg) {
        //const extendedProps = arg.resource.extendedProps;
        const isParent = !arg.resource._resource.parentId;
        const colorClasses = ['bgBlue', 'bgRed', 'bgYellow', 'bgPurple', 'bgCyan'];
        const classNames = ['extraCol'];

        if (isParent) {
          classNames.push(colorClasses[Math.floor(Math.random() * colorClasses.length)]);
        } else {
          //classNames.push('bgWhite');
        }

        return classNames;
      },
    },
    {
      field: 'id',
      headerContent: '',
      headerClassNames: 'hide',
      cellContent: function (_arg) {
        return '';
      },
      cellClassNames: function (arg) {
        //const extendedProps = arg.resource.extendedProps;
        const isParent = !arg.resource._resource.parentId;
        const colorClasses = ['bgBlue', 'bgRed', 'bgYellow', 'bgPurple', 'bgCyan'];
        const classNames = ['extraCol'];

        if (isParent) {
          classNames.push(colorClasses[Math.floor(Math.random() * colorClasses.length)]);
        } else {
          //classNames.push('bgWhite');
        }

        return classNames;
      },
    },
  ];

  return (
    <div>
      <div ref={filtersRef}>
        <GanttFilter
          type={type}
          setType={setType}
          params={params}
          saveView={saveView}
          submenuNames={submenuNames}
          submenu={submenu}
          setSimpleTaskDialogOpen={setSimpleTaskDialogOpen}
          toggleResourceGroup={toggleResourceGroup}
          setStatusDialogOpen={setStatusDialogOpen}
          showWorHourState={{ showWorkhour, setShowWorkHour }}
          disappearedWorkHour={disappearedWorkHour}
          filter={{ filterVal, setFilterVal }}
        />
      </div>
      <div className={`projectCalendar ${showWorkhour ? 'withWorkHour' : ''}`}>
        <FullCalendar
          ref={calendarRef}
          customButtons={timeIntervalButtons}
          plugins={[resourceTimelinePlugin, interactionPlugin]}
          initialView={ganttFilter?.selectedView ?? 'resourceTimelineDay'}
          resources={tasks.resources}
          events={[...tasks.taskEvents, ...tasks.backgroundEvents, ...projectInterval]}
          resourceOrder="visOrder"
          locales={[huLocale]}
          locale="hu"
          resourcesInitiallyExpanded={expandedResources}
          slotLabelFormat={[
            { month: 'long', year: 'numeric', weekday: 'long' },
            { hour: 'numeric', minute: '2-digit' },
          ]}
          datesSet={handleDatesSet}
          editable={true}
          nowIndicator={true}
          eventClick={(clickInfo) => {
            if (clickInfo.event.display !== 'background') {
              const id = clickInfo.event.id;
              if (clickInfo.jsEvent.ctrlKey) {
                setCopiedEvent(clickInfo.event);
              } else {
                getTaskById(id);
              }
            }
          }}
          dateClick={handleDateClick}
          headerToolbar={{
            left: `prev,next,today ${intervalButtons}`,
            center: 'title',
            right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth,resourceTimelineYear',
          }}
          eventContent={(eventInfo) => {
            if (eventInfo.event.display !== 'background') {
              return <EventChip eventInfo={eventInfo} />;
            }

            if (eventInfo?.event?._def?.extendedProps?.eventData?.elapsedHour) {
              return <div className="workHourEvent">{eventInfo?.event.title}</div>;
            }
          }}
          eventResize={updateEvent}
          eventDrop={updateEvent}
          views={calendarViews}
          slotDuration={timeInterval}
          resourceAreaWidth="20%"
          resourceAreaHeaderContent="Projekt"
          resourceAreaColumns={resourceColumns}
          scrollTimeReset={false}
          resourceLabelClassNames={(data) => {
            if (!data.resource._resource.parentId) {
              return ['fc-res-group'];
            }
          }}
        />
      </div>

      <StatusDialog
        open={statusDialogOpen}
        saveView={saveView}
        submenu={submenu}
        filter={{ filterVal, setFilterVal }}
        handleClose={() => {
          setStatusDialogOpen(false);
        }}
      />

      <SimpleTaskDialog
        open={dialogOpen}
        changed={{ taskChanged, setTaskChanged }}
        task={task}
        handleClose={() => {
          setDialogOpen(false);
        }}
      />

      <SimpleTaskDialog
        changed={{ taskChanged, setTaskChanged }}
        open={simpleTaskDialogOpen}
        handleClose={() => {
          setSimpleTaskDialogOpen(false);
        }}
      />
    </div>
  );
};
