import React from 'react';
import { Field } from 'formik';

import { InputUI, DatePickerUI, AutoCompleteSelectUI, FormLabelUI, FormErrorUI } from '../Interface';

export const FormFields = ({ viewField, submenuId, selectedValues, values, validateField, setFieldValue, errors }) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-3 mx-4 mb-5">
      <div className="col-span-4">
        <Field
          type="text"
          name="projectName"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Projekt neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.projectName} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.projectName !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="companyId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Cég" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.companyId} />}
          onChange={(_e, newVal) => {
            setFieldValue('companyId', newVal?.value ?? null).then(() => {
              validateField('companyId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.companyId !== '' }}
          selectedValue={values.companyId}
          selectedLabelValue={selectedValues?.company?.companyName}
          table={'company'}
          listType={{ id: 'companyId', name: 'companyName' }}
          conditions={`company.archive='N'`}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="statusId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Státusz" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.statusId} />}
          onChange={(_e, newVal) => {
            setFieldValue('statusId', newVal?.value ?? null).then(() => {
              validateField('statusId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.statusId !== '' }}
          selectedValue={values.statusId}
          selectedLabelValue={selectedValues?.status?.itemName}
          table={['statusitems', 'status']}
          listType={{ id: 'itemId', name: 'itemName' }}
          joins={'statusitems.statusId = status.statusId'}
          conditions={[`statusitems.archive = 'N'`, `status.subMenuId = ${submenuId}`]}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="date"
          name="projectStartDate"
          component={DatePickerUI}
          value={values.projectStartDate}
          fullWidth
          label={<FormLabelUI text="Kezdő dátum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.projectStartDate} />}
          onChange={(date) => {
            setFieldValue('projectStartDate', date).then(() => {
              validateField('projectStartDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.projectStartDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="date"
          name="projectEndDate"
          component={DatePickerUI}
          value={values.projectEndDate}
          fullWidth
          label={<FormLabelUI text="Befejező dátum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.projectEndDate} />}
          onChange={(date) => {
            setFieldValue('projectEndDate', date).then(() => {
              validateField('projectEndDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.projectEndDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="projectGroupId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Projekt csoport" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.projectGroupId} />}
          onChange={(_e, newVal) => {
            setFieldValue('projectGroupId', newVal?.value ?? null).then(() => {
              validateField('projectGroupId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.projectGroupId !== '' }}
          selectedValue={values.projectGroupId}
          selectedLabelValue={selectedValues?.projectGroup?.itemName}
          table={['ProjectGroupListitems', 'ProjectGroup']}
          joins={'ProjectGroupListitems.projectGroupStatusId = ProjectGroup.projectGroupStatusId'}
          listType={{ id: 'itemId', name: 'itemName' }}
          conditions={`ProjectGroup.subMenuId = ${submenuId}`}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="projectPrepare"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Készültség" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.projectPrepare} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.projectPrepare !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="userId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Tulajdonos" />}
          variant="standard"
          disabled={viewField}
          helperText={<FormErrorUI message={errors.userId} />}
          onChange={(_e, newVal) => {
            setFieldValue('userId', newVal?.value ?? null).then(() => {
              validateField('userId');
            });
          }}
          InputLabelProps={{ shrink: values.userId !== '' }}
          selectedValue={values.userId}
          selectedLabelValue={selectedValues?.user?.userName}
          table={'WebUser'}
          listType={{ id: 'userId', name: 'userName' }}
          conditions={[`WebUser.archive = 'N'`]}
        />
      </div>
    </div>
  );
};
