import { useState } from 'react';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';

import productionService from '../../service/production.service';
import { dataGridColumns } from '../../utils/helper';
import { useLoader } from '../../provider/LoaderProvider';

import AddDialog from './AddDialog';
import { DataGridUI, ButtonUI, DeleteDialogUI } from '../Interface';

const ProductionPersons = ({
  setProductionPersonList,
  productionPersonList,
  setRefetchTable,
  disabled,
  productionId,
}) => {
  const { showLoader, hideLoader } = useLoader();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [removeId, setRemoveId] = useState(null);
  const [addModalShow, setAddModalShow] = useState(false);

  const removeMember = (id) => {
    showLoader();
    productionService
      .deletePersonFromList(id)
      .then(() => {
        addNotification({
          content: 'Sikeres törlés!',
          type: NotificationType.WARNING,
        });
        setProductionPersonList(productionPersonList.filter((item) => item.id !== id));
        setRefetchTable(true);
      })
      .finally(() => {
        hideLoader();
        setShowDeleteDialog(false);
      });
  };

  const deleteIcon = (disabled, params) => {
    return (
      <div
        disabled={disabled}
        className={disabled ? 'hidden' : 'block cursor-pointer'}
        onClick={(e) => {
          e.stopPropagation();
          setRemoveId(params.row.id);
          setShowDeleteDialog(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-[20px] h-[20px]"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line> <line x1="6" y1="6" x2="18" y2="18"></line>{' '}
        </svg>
      </div>
    );
  };

  const columns = dataGridColumns([
    {
      field: 'userId',
      headerName: 'Azon.',
      flex: 0.5,
    },
    {
      field: 'userName',
      headerName: 'Név',
      flex: 1,
      valueGetter: (data) => {
        return data.row.user.userName;
      },
    },
    {
      field: 'roleName',
      headerName: 'Szerep',
      flex: 1,
    },
    {
      field: 'removeTag',
      headerName: '',
      flex: 0.4,
      renderCell: (params) => {
        return deleteIcon(disabled, params);
      },
    },
  ]);

  return (
    <div className="px-4">
      <ButtonUI
        text="Hozzáadás"
        type="button"
        disabled={disabled}
        onClick={() => {
          setAddModalShow(true);
        }}
        className="mb-2 bg-success block rounded-md px-3 disabled:cursor-not-allowed disabled:opacity-50 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      />
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <DataGridUI
              sx={{ height: 440, width: '100%' }}
              columns={columns}
              rows={productionPersonList}
              getRowId={(row) => row.id}
            />
          </div>
        </div>
      </div>
      <DeleteDialogUI
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
        handleRemoveElement={() => removeMember(removeId)}
      />
      <AddDialog
        show={addModalShow}
        setProductionPersonList={setProductionPersonList}
        onHide={() => setAddModalShow(false)}
        productionId={productionId}
      />
    </div>
  );
};

export default ProductionPersons;
