export const MainCategoryOption = [
  { value: 1, label: 'Szabadság' },
  { value: 2, label: 'Betegszabadság' },
];

export const SubCategoryOption = {
  1: [
    { value: 1, label: 'Engedélyezett' },
    { value: 2, label: 'Nem engedélyezett' },
  ],
  2: [
    { value: 3, label: 'Beteg, de nem küldött még betegpapírt' },
    { value: 4, label: 'Beteg, megküldte a papírját' },
    { value: 5, label: '6 héten túli beteg' },
  ],
};
