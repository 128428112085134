import { useState, useEffect } from 'react';
import _ from 'lodash';
import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';

import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import projectGroupService from '../../service/projectGroup.service';
import useMenus from '../../context/MenuContext';
import FormTabs from './FormTabs';
import ProjectGroupReports from './ProjectGroupReports';
import { FormHeaderUI, FormButtonPanelUI } from '../Interface';
import { FormFields } from './FormFields';

export const ProjectGroupForm = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const [refetchTable, setRefetchTable] = useState(true);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [projectGroupList, setprojectGroupList] = useState([]);
  const [canEdit, setCanEdit] = useState(true);

  const [operatorsDisabled, setOperatorsDisabled] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultValues, selectedValues)) {
        setprojectGroupList(selectedValues?.projectGroupListitems ?? []);
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  useEffect(() => {
    getMenus();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('ProjectGroup');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  const defaultValues = {
    selectListTitle: '',
    subMenuId: null,
  };

  const validationSchema = Yup.object().shape({
    selectListTitle: Yup.string().required(),
    subMenuId: Yup.string().required(),
  });

  const valuesToForm = () => {
    const { selectListTitle, subMenuId } = selectedValues;

    setInitValues({
      selectListTitle,
      subMenuId,
    });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const updateObject = (values) => {
    const updData = {
      ...values,
    };

    projectGroupService.updateProjectGroup(updData, selectedValues.projectGroupStatusId).then((_projectgroup) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setSelectedValues({});
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = (values) => {
    const insertObject = {
      ...values,
    };

    projectGroupService
      .createProjectGroup(insertObject)
      .then(() => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setSelectedValues({});
        setRefetchTable(true);
        setEditButtonvisible(true);
        setAddField(true);
        setViewField(true);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-6 grid-rows-[auto_1fr] gap-y-4 xl:gap-4 bg-gray-100 p-4">
      <div className="col-span-4 md:col-span-2 w-full overflow-x-auto overflow-y-auto">
        <div className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues({});
              setInitValues(defaultValues);
              setSelectedValues({});
              setViewField(false);
              setAddField(false);
              setprojectGroupList([]);
            }}
            addDisabled={!viewField || !canEdit}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
          />
          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
            //validateOnMount={false}
          >
            {({ values, setFieldValue, errors, validateField, resetForm }) => (
              <Form>
                <FormFields
                  viewField={viewField}
                  selectedValues={selectedValues}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  validateField={validateField}
                />
                {/*<FormFields values={{ initValues, setInitValues }} viewField={viewField} submenuId={id} />*/}

                {addField && (
                  <FormTabs
                    projectGroupStatusId={selectedValues?.projectGroupStatusId}
                    disabled={viewField}
                    projectGroupList={projectGroupList}
                    setprojectGroupList={setprojectGroupList}
                    setRefetchTable={setRefetchTable}
                    viewField={viewField}
                    selectedValues={selectedValues}
                  />
                )}

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <ProjectGroupReports
        refetch={{ refetchTable, setRefetchTable }}
        values={{ selectedValues, setSelectedValues }}
        viewField={viewField}
        operatorsDisabled={operatorsDisabled}
      />
    </div>
  );
};
