import React, { useCallback, useEffect, useState } from 'react';

import { DataGridUI } from '../../Interface';
import { dataGridColumns } from '../../../utils/helper';
import biService from '../../../service/bi.service';
import { isEqual } from 'lodash';
import { BiFieldType } from '../../../config/bi_report';
import dayjs from 'dayjs';

const TableReport = ({ report }) => {
  const [gridData, setGridData] = useState({ rows: [], rowCount: 0 });
  const [columns, setColumns] = useState([]);
  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  const getTableData = useCallback(() => {
    biService.getReportData(report.id, query).then((data) => setGridData(data));
  }, [report.id, query]);

  const generateColumns = () => {
    const col = report.biField.map((field) => {
      const column = {
        field: field?.field,
        headerName: field?.name,
        flex: 1,
      };

      switch (field.type) {
        case BiFieldType.DATE:
          column.valueGetter = (data) => dayjs(data.row?.[field?.field]).format('YYYY.MM.DD');
          break;
        case BiFieldType.DATETIME:
          column.valueGetter = (data) => dayjs(data.row?.[field?.field]).format('YYYY.MM.DD HH:mm');
          break;
        case BiFieldType.TIME:
          column.valueGetter = (data) => dayjs(data.row?.[field?.field]).format('HH:mm');
          break;
        case BiFieldType.NUMBER:
          column.valueGetter = (data) => parseFloat(data.row?.[field?.field]);
          break;
        default:
          break;
      }

      return column;
    });

    setColumns(dataGridColumns(col));
  };

  useEffect(() => {
    if (Object.keys(report).length) {
      generateColumns();
    }
  }, [report, report?.biField]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getTableData();
    }
  }, [query]);

  useEffect(() => {
    setQuery({
      filter: [],
      sorting: {
        sortModel: [{ field: report?.biField[0]?.field, sort: 'asc' }],
      },
      pagination: {
        paginationModel: {
          pageSize: 25,
          page: 0,
        },
      },
    });
  }, [columns]);

  const onPaginitonChange = (paginationModel) => {
    if (!isEqual(paginationModel, query.pagination.paginationModel)) {
      setQuery((prev) => {
        return { ...prev, pagination: { paginationModel } };
      });
    }
  };

  const handleSortModelChange = (sortModel) => {
    if (!isEqual(sortModel, query.sorting.sortModel)) {
      setQuery((prev) => {
        const paginationModel = {
          pageSize: prev.pagination.paginationModel.pageSize,
          page: 0,
        };

        return {
          ...prev,
          sorting: { sortModel },
          pagination: { paginationModel },
        };
      });
    }
  };

  return (
    <div className="grid grid-cols-1 gap-2 p-4">
      <div className="col-span-1 flex items-center justify-center text-labelColor text-xl">{report.name}</div>
      <div>
        <DataGridUI
          initialState={query}
          rows={gridData.rows}
          rowCount={gridData.rowCount}
          columns={columns}
          /*onRowClick={(rowData) => {
          setSelectedValues(rowData.row);
        }}*/
          paginationMode="server"
          onPaginationModelChange={onPaginitonChange}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          disableRowSelectionOnClick={false}
          name={`biGrid_${report.id}`}
        />
      </div>
    </div>
  );
};

export default TableReport;
