import { useEffect, useState } from 'react';
import { Chip } from '@material-tailwind/react';
import { DialogUI } from '../Interface';
import { KanbanDialog } from '../../config/kanban';
import { ColorPicker } from '../../config/ColorPicker';
import dayjs from 'dayjs';

const Field = ({ label, value, chipColor, isChip, dateType }) => {
  let formattedValue = value;
  if (dateType) {
    switch (dateType) {
      case 'date':
        formattedValue = dayjs(value).format('YYYY-MM-DD');
        break;
      case 'datetime':
        formattedValue = dayjs(value).format('YYYY-MM-DD HH:mm:ss');
        break;
      case 'time':
        formattedValue = dayjs(value).format('HH:mm:ss');
        break;
      default:
        formattedValue = value;
    }
  }
  if (isChip) {
    return (
      <div>
        <h4 className="font-semibold">{label}</h4>
        <div className="flex">
          <Chip
            value={formattedValue}
            className="rounded-full"
            variant="ghost"
            style={{ backgroundColor: chipColor }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="mb-3">
        <div>
          <h4 className="font-semibold">{label}</h4>
          <p>{formattedValue}</p>
        </div>
      </div>
    );
  }
};

const Dialog = ({ open, handleClose, data, type, params }) => {
  const [dialogParams, setDialogParams] = useState(null);

  useEffect(() => {
    const kanbanDialogConfig = KanbanDialog.find((card) => card.type === type);
    const paramName = kanbanDialogConfig?.paramName;

    if (params[paramName]) {
      const parsedParams = JSON.parse(params[paramName]);
      setDialogParams(parsedParams);
    }
  }, [params, type]);

  const getFieldValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => {
      if (Array.isArray(acc)) {
        return acc.map((item) => item[part]);
      } else {
        return acc ? acc[part] : null;
      }
    }, obj);
  };

  const getChipColor = (fieldColor) => {
    if (!fieldColor) return null;
    const color = getFieldValue(data, fieldColor);
    const colorObj = ColorPicker.find((col) => col.colorName === color);
    return colorObj ? colorObj.colorCode : color;
  };

  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent={data.name}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <div className="mt-5">
        <div className="block grid-cols-4 gap-x-4 gap-y-2 mx-4 md:grid text-labelColor">
          {dialogParams &&
            dialogParams.map((item, index) => (
              <Field
                key={index}
                label={item.label}
                value={getFieldValue(data, item.fieldName) || 'Nincs megadva.'}
                chipColor={getChipColor(item.color)}
                isChip={item.view === 'chip'}
                dateType={item.dateType}
              />
            ))}
        </div>
      </div>
    </DialogUI>
  );
};

export default Dialog;
