import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { GridRowModes, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import { DataGridUI, ButtonUI, AutoCompleteSelectUI } from '../Interface';
import { dataGridColumns } from '../../utils/helper';
import simpletaskService from '../../service/simpletask.service';
import userService from '../../service/user.service';

function EditToolbar(props) {
  const { setRows, setRowModesModel, disabled, rows } = props;

  const handleClick = () => {
    const newRow = {
      respPersonListId: Date.now(),
      roleName: '',
      userId: null,
      isNew: true,
    };

    setRows((oldRows) => [newRow, ...oldRows]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newRow.respPersonListId]: { mode: GridRowModes.Edit, fieldToFocus: 'simpleTaskId' },
    }));
  };

  const isNewRow = rows.some((row) => row.isNew);

  return (
    <GridToolbarContainer>
      <ButtonUI
        className="bg-success mb-1"
        text="Felelős hozzáadása"
        disabled={disabled || isNewRow}
        onClick={handleClick}
      />
    </GridToolbarContainer>
  );
}

const FormTable = ({ memberList, simpleTaskId, disabled, setRefetchTable, setMemberList }) => {
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getAllUser();
  }, []);

  useEffect(() => {
    setRows(memberList);
  }, [memberList]);

  const getAllUser = () => {
    userService.getAllUser().then((data) => {
      setUsers(data);
    });
  };

  const handleAddRow = (updatedRow) => {
    simpletaskService.addRespPersonToList(updatedRow, simpleTaskId).then((data) => {
      addNotification({
        content: 'Sikeres hozzáadás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setMemberList((prev) => {
        return [...prev, data];
      });
    });
  };

  const handleUpdateRow = (updatedRow, respPersonListId) => {
    simpletaskService.updateRespPersonList(updatedRow, respPersonListId).then(() => {
      addNotification({
        content: 'Sikeres mentés!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
    });
  };

  const handleDeleteRow = (respPersonListId) => {
    simpletaskService.deleteRespPersonFromList(respPersonListId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
    });
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (respPersonListId) => () => {
    setRows(rows.filter((row) => row.respPersonListId !== respPersonListId));
    handleDeleteRow(respPersonListId);
  };

  const handleCancelClick = (id) => () => {
    const editedRow = rows.find((row) => row.respPersonListId === id);

    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.respPersonListId !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    if (!newRow.userId || newRow.userId <= 0 || newRow.roleName.trim() === '') {
      addNotification({
        content: 'A mezők kitöltése kötelező!',
        type: NotificationType.WARNING,
      });
      return;
    }

    const updatedRow = { ...newRow, isNew: newRow.isNew ?? false };
    const updatedRows = rows.map((row) => (row.respPersonListId === newRow.respPersonListId ? updatedRow : row));

    updatedRows.sort((a, b) => a.respPersonListId - b.respPersonListId);

    setRows(updatedRows);

    if (updatedRow.isNew) {
      handleAddRow(updatedRow);
    } else {
      handleUpdateRow(updatedRow, newRow.respPersonListId);
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const getUserById = (id) => {
    const user = users.find((item) => item.userId === id);
    return user ? user.userName : id;
  };

  const columns = dataGridColumns([
    {
      field: 'respPersonListId',
      headerName: 'Azon.',
      flex: 0.5,
      valueGetter: (params) => (params?.row.isNew ? '' : params.row.respPersonListId),
    },
    {
      field: 'userId',
      headerName: 'Név',
      flex: 1,
      renderCell: (params) => getUserById(params.value),
      renderEditCell: (params) => (
        <AutoCompleteSelectUI
          options={users.map((user) => user.userId)}
          getOptionLabel={(option) => {
            const user = users.find((item) => item.userId === option);
            return user ? user.userName : '';
          }}
          value={params.row.userId}
          onChange={(_event, newValue) => {
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: newValue ? newValue : null,
            });
          }}
        />
      ),
      editable: true,
    },
    {
      field: 'roleName',
      headerName: 'Szerep',
      flex: 1,
      valueGetter: (params) => {
        return params.value ?? 'Nincs adat.';
      },
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Szerk.',
      flex: 0.5,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              disabled={disabled}
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            disabled={disabled}
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            disabled={disabled}
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ]);

  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <DataGridUI
            sx={{ height: 300 }}
            columns={columns}
            rows={rows}
            getRowId={(row) => row.respPersonListId}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{
              toolbar: EditToolbar,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel, simpleTaskId, rows, disabled, memberList, rowModesModel },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FormTable;
