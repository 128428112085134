import { useMemo } from 'react';
import useUsers from '../context/UsersContext';

const useView = (viewName) => {
  const { user } = useUsers();

  const viewData = useMemo(() => user?.views?.[viewName] || {}, [user, viewName]);

  return viewData;
};

export default useView;
