export const objectToArray = (obj) => {
  return Object.entries(obj).map(([key, value]) => ({ field: key, value }));
};

export const generateFilter = (formValues, conditions) => {
  const { like, gte, lte } = conditions;

  const values = objectToArray(formValues);
  const retVal = values.filter((value) => {
    if (value.value !== '' && value.value !== null) {
      if (like?.includes(value.field)) {
        value.like = true;
      } else if (gte?.includes(value.field)) {
        value.gte = true;
      } else if (lte?.includes(value.field)) {
        value.lte = true;
      }
      return value;
    }
    return false;
  });

  return retVal;
};

export const dataGridColumns = (gridCols) => {
  return gridCols.map((col) => {
    return {
      ...col,
      filterable: false,
      headerClassName: 'bg-tableHeaderColor',
    };
  });
};
