import React, { createContext, useContext, useReducer } from 'react';
import { usersReducer } from '../reducer/usersReducer';

const UsersContext = createContext({});

export const UsersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(usersReducer, {});

  const setUser = (user) => {
    return dispatch({ type: 'SET', payload: user });
  };

  const getUser = () => {
    return dispatch({ type: 'GET' });
  };

  const updateViews = (views) => {
    dispatch({ type: 'UPDATE_VIEWS', payload: views });
  };

  const value = {
    user: state,
    setUser,
    getUser,
    updateViews,
  };

  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
};

const useUsers = () => {
  return useContext(UsersContext);
};

export default useUsers;
