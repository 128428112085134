import { Checkbox, FormControlLabel } from '@mui/material';

const CheckboxUI = (props) => {
  const { label, checked, onChange } = props;

  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} />} label={label} {...props} />;
};

export default CheckboxUI;
