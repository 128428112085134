import { useCallback } from 'react';
import { useFormikContext } from 'formik';

import { getIndex } from '../utils/DynHelper';

const useSetDynField = () => {
  const { setFieldValue, values } = useFormikContext();

  const setVal = useCallback(
    (name, value) => {
      let prevDynFields = values?.dynamicFields;

      if (!prevDynFields) {
        return setFieldValue(`dynamicFields`, {});
      }
      const fieldId = getIndex(name);

      prevDynFields[fieldId] = value;

      setFieldValue(`dynamicFields`, prevDynFields);
    },
    [setFieldValue, values]
  );

  return setVal;
};

export default useSetDynField;
