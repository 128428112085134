import { useEffect, useState } from 'react';
import kanbanService from '../../service/kanban.service';
import { useLoader } from '../../provider/LoaderProvider';

import useParam from '../../context/ParamContext';
import useUsers from '../../context/UsersContext';
import { ViewType, KanbanType, conditions, KanbanFilterFormState, getKanbanFooterTitle } from '../../config/kanban';
import { generateFilter } from '../../utils/helper';
import { ColorPicker } from '../../config/ColorPicker';
import Card from './Card';
import { AutoCompleteSelectUI, ButtonUI, DebouncedInputUI, FormLabelUI, SelectUI } from '../Interface';
import Dialog from './Dialog';

const KanbanView = () => {
  const { getUser, user } = useUsers();
  const { getParam, params } = useParam();
  const { showLoader, hideLoader } = useLoader();
  const [kanbanData, setKanbanData] = useState([]);
  const [item, setItem] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [filterFormState, setFilterFormState] = useState({});
  const [loading, setLoading] = useState(null);
  const [type, setType] = useState('');
  const [defaultFilterFields, setDefaultFilterFields] = useState([]);

  const [query, setQuery] = useState({
    filter: [],
  });

  useEffect(() => {
    getUser();
    getParam();
  }, []);

  useEffect(() => {
    if (type === '') {
      setType(KanbanType.TASK);
    }
  }, []);

  const kanbanViewAccess =
    params.KANBAN_VIEW_ACCESS && params.KANBAN_VIEW_ACCESS.split(',').map((access) => parseInt(access));

  const hasAccess = kanbanViewAccess && kanbanViewAccess.includes(user.userId) ? 'Y' : 'N';

  useEffect(() => {
    if (type) {
      setFilterFormState((prev) => ({
        ...KanbanFilterFormState[type],
        ...(type === KanbanType.TASK && { resposiblePersonId: user.userId }),
      }));
    }
  }, [type, user.userId]);

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'archive',
        value: 'N',
      },
    ];

    const currentConditions = conditions[type || KanbanType.TASK];
    const newFilter = generateFilter(filterFormState, currentConditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
    setDefaultFilterFields(defaultFilter);
  }, [filterFormState]);

  useEffect(() => {
    if (defaultFilterFields.length > 0 && query.filter.length > 0) {
      getItemsByType();
    }
  }, [query]);

  const getItemsByType = () => {
    showLoader();
    kanbanService
      .getItemsByType(type, query, hasAccess)
      .then((data) => {
        setKanbanData(data);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getItemById = (id) => {
    showLoader();
    kanbanService
      .getItemById(type, id)
      .then((data) => {
        setItem(data);
        setDialogOpen(true);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleDropAndStatusUpdate = (statusId, kanbanItem) => {
    setLoading(kanbanItem.id);
    const newStatus = kanbanData.find((kanbanColumn) => kanbanColumn.itemId === statusId);

    const updatedColumn = {
      ...kanbanItem,
      statusId: statusId,
      status: {
        ...kanbanItem.status,
        itemName: newStatus.itemName,
        color: newStatus.color,
        statusId: newStatus.itemId,
      },
    };

    setKanbanData((prev) => {
      const updatedKanbanData = prev.map((kanbanColumn) => {
        if (kanbanColumn.itemId === statusId) {
          return {
            ...kanbanColumn,
            items: [updatedColumn, ...kanbanColumn.items],
          };
        }
        if (kanbanColumn.itemId === kanbanItem.statusId) {
          return {
            ...kanbanColumn,
            items: kanbanColumn.items.filter((t) => t.id !== kanbanItem.id),
          };
        }
        return kanbanColumn;
      });
      return updatedKanbanData;
    });

    kanbanService.updateItemsByType(type, kanbanItem.id, statusId, updatedColumn).finally(() => {
      setLoading(null);
    });
  };

  const onDragStart = (event, kanbanItem) => {
    event.dataTransfer.setData('kanbanItem', JSON.stringify(kanbanItem));
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = (event, statusId) => {
    event.preventDefault();

    if (loading) {
      return;
    }

    const kanbanItem = JSON.parse(event.dataTransfer.getData('kanbanItem'));

    if (kanbanItem.statusId !== statusId) {
      handleDropAndStatusUpdate(statusId, kanbanItem);
    }
  };

  return (
    <div className="bg-gray-100 w-full px-3 h-screen md:fixed z-40">
      <div className="absolute -top-14 right-20  mt-1">
        <ButtonUI text="Frissítés" className="bg-success" onClick={getItemsByType} />
      </div>
      <div className="absolute -top-16 left-72">
        <div className="flex gap-10">
          <div className="w-[150px]">
            <SelectUI
              label={<FormLabelUI text="Nézet" />}
              option={ViewType}
              value={type}
              onChange={(e) => {
                const value = e.target.value;
                setType(value);
              }}
            />
          </div>
          <div>
            {conditions[type]?.like?.map((fieldName) => (
              <DebouncedInputUI
                key={fieldName}
                label={<FormLabelUI text="Név" />}
                debounceMs={800}
                fieldName={fieldName}
                setQuickFilterSearchValue={(fieldName, newValue) => {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    [fieldName]: newValue,
                  }));
                }}
                quickFilterSearchValue={filterFormState[fieldName]}
              />
            ))}
          </div>
          <div className={`${type !== KanbanType.TASK ? 'hidden' : 'w-[250px]'}`}>
            <AutoCompleteSelectUI
              variant="standard"
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prev) => ({
                    ...prev,
                    resposiblePersonId: '',
                  }));
                } else {
                  setFilterFormState((prev) => ({
                    ...prev,
                    resposiblePersonId: newVal.value,
                  }));
                }
              }}
              selectedValue={filterFormState.resposiblePersonId}
              selectedLabelValue={user?.userName}
              label={<FormLabelUI text="Felelős" />}
              table={'WebUser'}
              listType={{ id: 'userId', name: 'userName' }}
              conditions={[`WebUser.archive = 'N'`]}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-5 justify-center mt-4 md:flex-grow md:flex-row">
        {kanbanData.map((kanban) => {
          const statusColor = ColorPicker.find((col) => col.colorName === kanban.color);
          return (
            <div
              className="basis-1/4 text-labelColor relative"
              key={kanban.itemId}
              onDragOver={(event) => onDragOver(event)}
              onDrop={(event) => onDrop(event, kanban.itemId)}
            >
              <div
                className="bg-white py-4 pl-2 shadow-xl text-center text-xl font-bold"
                style={{ borderBottom: `3px solid ${statusColor?.colorCode}` }}
              >
                {kanban.itemName}
              </div>
              <div
                className="bg-kanbanBg shadow-xl w-full h-[350px] p-3 overflow-y-auto pb-10 md:h-screen"
                style={{ maxHeight: 'calc(100vh - 160px)' }}
              >
                {kanban.items.map((data) => {
                  return (
                    <Card
                      type={type}
                      key={data.id}
                      data={data}
                      onDragStart={onDragStart}
                      getItemById={getItemById}
                      statusColor={statusColor}
                      params={params}
                      loading={loading === data.id ? loading : null}
                    />
                  );
                })}
              </div>
              <div className="absolute bottom-0 z-50 bg-kanbanBg w-full py-1 flex justify-center">
                <p className="font-bold">
                  {getKanbanFooterTitle(type)}: {kanban.items.length}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <Dialog
        open={dialogOpen}
        data={item}
        handleClose={() => {
          setDialogOpen(false);
          setItem({});
        }}
        type={type}
        params={params}
      />
    </div>
  );
};

export default KanbanView;
