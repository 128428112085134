import { useMemo, useState } from 'react';

import { Field } from 'formik';

import { FormErrorUI, FormLabelUI, InputUI, TabsUI } from '../Interface';
import DynamicPropertyMain from '../DynamicProperty/DynamicPropertyMain';
import ResourceOneTable from './Resources/ResourceOneTable';
import ResourceTwoTable from './Resources/ResourceTwoTable';
import ResourceThreeTable from './Resources/ResourceThreeTable';
import ResourceFourTable from './Resources/ResourceFourTable';
import ResourceFiveTable from './Resources/ResourceFiveTable';
import DragAndDrop from '../ui/DragAndDrop';
import ProjectContact from './ProjectContact';
import ProjectHoliday from './ProjectHoliday';

const DescriptionPanel = ({ disabled, errors, values }) => {
  return (
    <div>
      <div className="col-span-full relative">
        <div className="mt-2">
          <Field
            type="text"
            name="projectDescription"
            as={InputUI}
            className={`${disabled ? 'rounded-md' : ''} resize-none h-36, p-2`}
            fullWidth
            label={<FormLabelUI text="Leírás" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.projectDescription} />}
            disabled={disabled}
            InputLabelProps={{ shrink: values.projectDescription !== '' }}
            multiline
            rows={4}
          />
        </div>
      </div>
    </div>
  );
};

const FormTabs = ({
  submenuId,
  setFieldValue,
  validateField,
  disabled,
  setRefetchTable,
  setResourceList,
  resourceList,
  user,
  submenuNames,
  selectedValues,
  errors,
  values,
  addField,
  fileList,
  setFileList,
  newFiles,
  setNewFiles,
  params,
  contact,
  holiday,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      id: 0,
      name: 'Tulajdonságok',
      content: (
        <DynamicPropertyMain
          submenuId={submenuId}
          setFieldValue={setFieldValue}
          validateField={validateField}
          disabled={disabled}
          selectedValues={selectedValues}
        />
      ),
      key: 'PROPERTIES',
    },
    {
      id: 1,
      name: 'Megjegyzés',
      content: <DescriptionPanel errors={errors} values={values} disabled={disabled} />,
      key: 'DESCRIPTION',
    },
    {
      id: 2,
      name: submenuNames[0],
      content: addField && (
        <ResourceOneTable
          resourceList={resourceList}
          setResourceList={setResourceList}
          setRefetchTable={setRefetchTable}
          disabled={disabled}
          selectedValues={selectedValues}
          user={user}
        />
      ),
      key: 'RESOURCE_ONE',
    },
    {
      id: 3,
      name: submenuNames[1],
      content: addField && (
        <ResourceTwoTable
          resourceList={resourceList}
          setResourceList={setResourceList}
          setRefetchTable={setRefetchTable}
          disabled={disabled}
          selectedValues={selectedValues}
          user={user}
        />
      ),
      key: 'RESOURCE_TWO',
    },
    {
      id: 4,
      name: submenuNames[2],
      content: addField && (
        <ResourceThreeTable
          resourceList={resourceList}
          setResourceList={setResourceList}
          setRefetchTable={setRefetchTable}
          disabled={disabled}
          selectedValues={selectedValues}
          user={user}
        />
      ),
      key: 'RESOURCE_THREE',
    },
    {
      id: 5,
      name: 'Kapcsolattartó',
      content: addField && (
        <ProjectContact
          selectedValues={selectedValues}
          disabled={disabled}
          setRefetchTable={setRefetchTable}
          contact={contact}
          user={user}
        />
      ),
      key: 'CONTACT',
    },
    {
      id: 6,
      name: 'Csatolmányok',
      content: addField && (
        <DragAndDrop
          selectedValues={selectedValues}
          disabled={disabled}
          fileList={fileList}
          setFileList={setFileList}
          newFiles={newFiles}
          setNewFiles={setNewFiles}
        />
      ),
      key: 'ATTACHMENT',
    },
    {
      id: 7,
      name: 'Ünnepnapok',
      content: addField && (
        <ProjectHoliday
          selectedValues={selectedValues}
          disabled={disabled}
          setRefetchTable={setRefetchTable}
          holiday={holiday}
          user={user}
        />
      ),
      key: 'HOLIDAY',
    },
    {
      id: 8,
      name: submenuNames[3],
      content: addField && (
        <ResourceFourTable
          resourceList={resourceList}
          setResourceList={setResourceList}
          setRefetchTable={setRefetchTable}
          disabled={disabled}
          selectedValues={selectedValues}
          user={user}
        />
      ),
      key: 'RESOURCE_FOUR',
    },
    {
      id: 9,
      name: submenuNames[4],
      content: addField && (
        <ResourceFiveTable
          resourceList={resourceList}
          setResourceList={setResourceList}
          setRefetchTable={setRefetchTable}
          disabled={disabled}
          selectedValues={selectedValues}
          user={user}
        />
      ),
      key: 'RESOURCE_FIVE',
    },
  ];

  const visibleTabs = useMemo(() => {
    const tabVisibilityMap = params.PROJECT_TAB_FIELDS
      ? JSON.parse(params.PROJECT_TAB_FIELDS).reduce((acc, field) => {
          const [key, value] = Object.entries(field)[0];
          acc[key] = value === 'Y';
          return acc;
        }, {})
      : {};

    return tabs.filter((tab) => {
      if (tab.key === 'PROPERTIES') {
        return true;
      }
      if (!params.PROJECT_TAB_FIELDS) {
        return true;
      }
      if (tab.key.startsWith('RESOURCE_')) {
        const resourceIndex = parseInt(tab.key.split('_')[1]) - 1;
        return tabVisibilityMap[tab.key] && submenuNames[resourceIndex] !== null;
      }
      return tabVisibilityMap[tab.key];
    });
  }, [params.PROJECT_TAB_FIELDS, tabs, submenuNames]);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="my-4 mt-6">
      <div className="px-3">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {visibleTabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
    </div>
  );
};

export default FormTabs;
