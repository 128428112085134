import { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import projectService from '../../service/project.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';

import ProjectReports from './ProjectReports';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI } from '../Interface';
import { FormFields } from './FormFields';
import useUsers from '../../context/UsersContext';
import useMenus from '../../context/MenuContext';
import FormTabs from './FormTabs';
import useParam from '../../context/ParamContext';

export const ProjectForm = () => {
  const { user, getUser } = useUsers();
  const { menus, getMenus, getMenuSubMenuId, getSubMenuNamesByUrls, getCanEdit } = useMenus();
  const { getParam, params } = useParam();
  const [refetchTable, setRefetchTable] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);

  const [defaultValues, setDefaultValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [submenu, setSubmenu] = useState(null);
  const [resourceList, setResourceList] = useState({
    resourceOne: [],
    resourceTwo: [],
    resourceThree: [],
    resourceFour: [],
    resourceFive: [],
  });
  const [newFiles, setNewFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [holidayList, setHolidayList] = useState([]);
  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    getMenus();
    getUser();
    getParam();

    const def = {
      projectId: null,
      projectName: '',
      companyId: null,
      statusId: null,
      projectStartDate: dayjs(new Date()),
      projectEndDate: dayjs(new Date()),
      projectPrepare: '',
      userId: user, //@TODO legyeb disabled szerintem? Kérdezzük meg Attilát
      projectDescription: '',
      projectGroupId: null,
    };

    setDefaultValues(def);
    getMenus();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('Project');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls(['resourceOne', 'resourceTwo', 'resourceThree', 'resourceFour', 'machine']);
  }, [menus]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
        setResourceList((prev) => ({
          ...prev,
          resourceOne: selectedValues.projectResourceOne ?? [],
          resourceTwo: selectedValues.projectResourceTwo ?? [],
          resourceThree: selectedValues.projectResourceThree ?? [],
          resourceFour: selectedValues.projectResourceFour ?? [],
          resourceFive: selectedValues.projectResourceFive ?? [],
        }));
        setContactList(selectedValues.projectContact ?? []);
        setHolidayList(selectedValues.projectHoliday ?? []);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  useEffect(() => {
    setDefaultValues((prev) => {
      return { ...prev, userId: user.userId };
    });

    setDefaultSelectedValues((prev) => {
      return {
        ...prev,
        user: user,
      };
    });
  }, [user]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  const validationSchema = Yup.object().shape({
    projectId: Yup.string().nullable(),
    projectName: Yup.string().required(),
    companyId: Yup.string().nullable(),
    statusId: Yup.string().nullable(),
    projectStartDate: Yup.string().required(),
    projectEndDate: Yup.string().required(),
    projectPrepare: Yup.number().nullable().positive(),
    userId: Yup.string().required(),
    projectDescription: Yup.string(),
    projectGroupId: Yup.string().nullable(),
  });

  const valuesToForm = () => {
    const {
      projectId,
      projectName,
      companyId,
      statusId,
      projectStartDate,
      projectEndDate,
      projectPrepare,
      userId,
      projectDescription,
      projectGroupId,
    } = selectedValues;

    setInitValues({
      projectId,
      projectName,
      companyId,
      statusId,
      projectStartDate: dayjs(projectStartDate),
      projectEndDate: dayjs(projectEndDate),
      projectPrepare,
      userId,
      projectDescription,
      projectGroupId,
    });
  };

  const handleSubmit = (values) => {
    const projectResourceOne = resourceList.resourceOne || [];
    const projectResourceTwo = resourceList.resourceTwo || [];

    if (params.PROJECT_WARNINGS === 'Y') {
      if (projectResourceOne.length > 0) {
        const typeFourCount = projectResourceOne.filter(
          (resource) => resource.resourceOne?.type?.itemName === '4'
        ).length;

        if (typeFourCount < 2) {
          addNotification({
            content: 'A projekt nem tartalmaz két 4-es típusú dolgozót!',
            type: NotificationType.WARNING,
          });
        }
      }

      if (projectResourceOne.length > 0 && projectResourceTwo.length === 0) {
        addNotification({
          content: 'A projekt nem tartalmaz autókat!',
          type: NotificationType.WARNING,
        });
      }
    }

    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      projectPrepare: parseFloat(values.projectPrepare),
      projectStartDate: dayjs(values.projectStartDate).toISOString(),
      projectEndDate: dayjs(values.projectEndDate).toISOString(),
      formId: submenu,
      lineId: selectedValues.projectId,
    };

    projectService.updateProject(updData, selectedValues.projectId).then((_project) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const formatResources = (resourceArray, includeLeader = false) => {
      return resourceArray
        ? resourceArray.map((resource) => {
            const formattedResource = { resourceId: resource.resourceId };
            if (includeLeader && resource.leader) {
              formattedResource.leader = resource.leader;
            }
            return formattedResource;
          })
        : [];
    };

    const insertObject = {
      ...values,
      projectPrepare: parseFloat(values.projectPrepare),
      projectStartDate: dayjs(values.projectStartDate).toISOString(),
      projectEndDate: dayjs(values.projectEndDate).toISOString(),
      formId: submenu,
      projectResourceOne: formatResources(selectedValues?.projectResourceOne, true),
      projectResourceTwo: formatResources(selectedValues?.projectResourceTwo),
      projectResourceThree: formatResources(selectedValues?.projectResourceThree),
      projectResourceFour: formatResources(selectedValues?.projectResourceFour),
      projectResourceFive: formatResources(selectedValues?.projectResourceFive),
      projectContact: selectedValues?.projectContact,
    };

    projectService
      .createProject(insertObject)
      .then((_data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });

        setRefetchTable(true);
        setEditButtonvisible(true);
        setAddField(true);
        setViewField(true);
        setInitValues({});
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    projectService.deleteProject(selectedValues.projectId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultSelectedValues);
    });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-6 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem]">
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="h-full overflow-auto custom-scrollbar">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues(defaultSelectedValues);
              setInitValues(defaultValues);
              setViewField(false);
              setAddField(false);
            }}
            addDisabled={!viewField || !canEdit}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled || !canEdit}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
            //validateOnMount={false}
          >
            {({ values, setFieldValue, errors, validateField, resetForm }) => (
              <Form>
                <FormFields
                  viewField={viewField}
                  selectedValues={selectedValues}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  submenuId={submenu}
                  validateField={validateField}
                />

                <FormTabs
                  disabled={viewField}
                  submenuId={submenu}
                  addField={addField}
                  setFieldValue={setFieldValue}
                  validateField={validateField}
                  values={values}
                  selectedValues={selectedValues}
                  resourceList={resourceList}
                  setResourceList={setResourceList}
                  setRefetchTable={setRefetchTable}
                  user={user}
                  submenuNames={submenuNames}
                  errors={errors}
                  fileList={fileList}
                  setFileList={setFileList}
                  newFiles={newFiles}
                  setNewFiles={setNewFiles}
                  params={params}
                  contact={{ contactList, setContactList }}
                  holiday={{ holidayList, setHolidayList }}
                />

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white shadow sm:rounded-lg  ">
        <div className="h-full overflow-auto custom-scrollbar">
          <ProjectReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
            submenuId={submenu}
          />
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </div>
  );
};
