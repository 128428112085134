import { useState } from 'react';

import { TabsUI } from '../../Interface';
import DynamicPropertyMain from '../../DynamicProperty/DynamicPropertyMain';

const ResoureOneTabs = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      id: 0,
      name: 'Tulajdonságok',
      content: <DynamicPropertyMain {...props} />,
      key: 'PROPERTIES',
    },
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="my-4 mt-6">
      <div className="px-3">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {tabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
    </div>
  );
};

export default ResoureOneTabs;
