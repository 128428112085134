import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'attendence';

const attendenceService = {
  getAttendence: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/attendence`,
      data,
    });
  },

  createAttendence: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateAttendence: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deleteAttendence: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  addAttendenceUserToList: (data, id) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/attendenceUser/${id}`,
      data,
    });
  },

  deleteAttendenceUserFromList: (data, listId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/attendenceUser/${listId}`,
      data,
    });
  },

  updateAttendenceUser: (data, listId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/attendenceUser/${listId}`,
      data,
    });
  },

  copyAttendenceUser: (data, id) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/${id}`,
      data,
    });
  },
};

export default attendenceService;
