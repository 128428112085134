import React, { useCallback, useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import biService from '../../../service/bi.service';
import { BiReportType } from '../../../config/bi_report';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
//ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const BarReport = ({ report }) => {
  const [chartData, setChartData] = useState({});

  const getChartData = useCallback(() => {
    biService.getReportData(report.id).then((data) => setChartData(data));
  }, [report.id]);

  useEffect(() => {
    if (Object.keys(report).length > 0) {
      getChartData();
    }
  }, [report, report?.biField, getChartData]);

  const data = {
    labels: chartData.label,
    datasets: chartData?.chart ? chartData?.chart?.map((c) => ({ ...c, borderWidth: 1, hoverOffset: 4 })) : [],
  };

  const options = {
    ...(report.reportType === BiReportType.HORIZONTAL && { indexAxis: 'y' }),
    maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
      colors: {
        //enabled: false,
        forceOverride: true,
      },
      title: {
        display: true,
        text: report.name,
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        // itt van az onClick a soron ;)
        const label = data.labels[elements[0].index];
        //const value = data.datasets[elements.datasetIndex][elements.index];
        console.log(elements);
        console.log(label);
        console.log(data);
      }
    },
  };

  return (
    <div className="m-2 h-[650px] flex justify-center">
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarReport;
