import { useEffect, useState } from 'react';
import appService from '../../service/app.service';

import { ButtonUI } from '../Interface';
import RoleInformation from './RoleInformation';
import { AddRole } from './AddRole';

export default function RoleList() {
  const [role, setRole] = useState([]);
  const [roleId, setRoleId] = useState(null);

  const [showAddDialog, setShowAddDialog] = useState(false);

  const fetchData = async () => {
    appService
      .getRole()
      .then((data) => {
        setRole(data);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchData();
  }, [setRole]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="mx-5 mb-2">
        <ButtonUI
          type="button"
          text="Hozzáadás"
          onClick={() => {
            setShowAddDialog(true);
          }}
          className="bg-success"
        >
          Hozzáadás
        </ButtonUI>
      </div>
      <div className="grid grid-cols-4">
        <div className="col-span-1 h-screen overflow-y-auto px-3">
          {role &&
            role.map((p) => (
              <div
                key={p.roleId}
                onClick={() => setRoleId(p.roleId)}
                className="flex flex-col items-center h-16 justify-center text-center bg-white hover:bg-gray-200 border border-gray-200 rounded-md py-3 cursor-pointer p-4"
              >
                <p className="text-d font-semibold text-gray-800 text-sm">{p.roleName}</p>
              </div>
            ))}
        </div>
        <div className="col-span-3">
          <RoleInformation roleId={roleId} />
        </div>

        <AddRole
          show={showAddDialog}
          onHide={() => setShowAddDialog(false)}
          fetchData={fetchData}
          role={role}
          setRole={setRole}
        />
      </div>
    </>
  );
}
