import React from 'react';
import { DataGridUI } from '../Interface';
import { dataGridColumns } from '../../utils/helper';

const TaskReports = ({ reportList }) => {
  const columns = dataGridColumns([
    {
      field: 'timeSpent',
      headerName: 'Ráfordított idő',
      flex: 1,
      valueGetter: (params) => {
        const me = params.row.me;
        const timeSpent = params.row.timeSpent;
        const formattedTimeSpent = me === 'h' ? 'óra' : 'perc';
        return `${timeSpent} ${formattedTimeSpent}`;
      },
    },
    {
      field: 'typeId',
      headerName: 'Feladattípus',
      flex: 1,
      valueGetter: (params) => {
        const taskType = params.row.taskType?.itemName;
        return taskType;
      },
    },
    {
      field: 'description',
      headerName: 'Megjegyzés',
      flex: 1,
    },
  ]);

  return <DataGridUI sx={{ height: 440, width: '100%' }} columns={columns} rows={reportList} />;
};

export default TaskReports;
