import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Formik, Form } from 'formik';
import Yup from '../../../utils/yup';
import { isEqual } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import biService from '../../../service/bi.service';
import { NotificationType } from '../../../config';
import addNotification from '../../../utils/addNotification';
import BiFieldForm from './BiFieldForm';
import BiReportForm from './BiReportForm';
import BiReport from '../BiReport/BiReport';
import { BiReportType } from '../../../config/bi_report';

const BiEditorPage = () => {
  const navigate = useNavigate();
  const { reportId } = useParams();

  const defaultField = {
    field: null,
    type: '',
    name: '',
    isGroup: false,
    isFilter: false,
    filterType: null,
    filterFieldValue: [],
  };
  const defaultValues = useMemo(
    () => ({
      id: reportId,
      name: '',
      reportType: null,
      biHeadId: null,
      analysis: null,
      orderField: null,
      orderType: null,
      limit: '',
      biField: [],
    }),
    [reportId]
  );

  const [initValues, setInitValues] = useState({});
  const [autocompleteData, setAutocompleteData] = useState({
    biHeads: [],
    biField: [],
  });
  const [sourceData, setSourceData] = useState({
    biHeads: [],
    biField: [],
  });
  const [fieldErrors, setFieldErrors] = useState([]);
  const [selectedReport, setSelectedReport] = useState({});
  const [reRender, setReRender] = useState(false);

  const getFields = useCallback(
    (newVal) => {
      if (newVal) {
        const findedView = sourceData.biHeads.find((biHead) => biHead.id === newVal);
        if (findedView) {
          biService.getViewFields(findedView.element).then((data) => {
            setSourceData((prev) => {
              return { ...prev, biField: data };
            });
          });
        } else {
          setSourceData((prev) => {
            return { ...prev, biField: [] };
          });
          return addNotification({
            content: 'Hiba történt a forrás kiválasztása során!',
            type: NotificationType.WARNING,
          });
        }
      } else {
        setSourceData((prev) => {
          return { ...prev, biField: [] };
        });
      }
    },
    [sourceData.biHeads]
  );

  const getReport = useCallback(() => {
    biService.getReport(reportId).then((data) => {
      setSelectedReport(data);
      getFields(data.biHeadId);
    });
  }, [reportId, getFields]);

  useEffect(() => {
    getAllBiHeads();
  }, []);

  useEffect(() => {
    if (reportId && sourceData.biHeads.length) {
      getReport();
    }
  }, [reportId, sourceData.biHeads, getReport]);

  useEffect(() => {
    if (
      Object.keys(selectedReport).length > 0 &&
      autocompleteData.biHeads.length > 0 &&
      autocompleteData.biField.length > 0
    ) {
      setInitValues(selectedReport);
    } else {
      setInitValues(defaultValues);
    }
  }, [selectedReport, autocompleteData.biHeads, autocompleteData.biField, defaultValues]);

  useEffect(() => {
    setAutocompleteData((prev) => {
      const newAutocompleteData = { ...prev };

      if (!isEqual(sourceData.biHeads, prev.biHeads)) {
        newAutocompleteData.biHeads =
          sourceData.biHeads.length > 0
            ? sourceData.biHeads.map((biHead) => ({
                value: biHead.id,
                label: biHead.name,
              }))
            : [];
      }

      if (!isEqual(sourceData.biField, prev.biField)) {
        newAutocompleteData.biField =
          sourceData.biField.length > 0
            ? sourceData.biField.map((field) => ({
                value: field.COLUMN_NAME,
                label: field.COLUMN_NAME,
              }))
            : [];
      }

      return newAutocompleteData;
    });
  }, [sourceData]);

  useEffect(() => {
    if (reRender) setReRender(false);
  }, [reRender]);

  const getAllBiHeads = () => {
    biService.getAllBiHeads().then((data) =>
      setSourceData((prev) => {
        return { ...prev, biHeads: data };
      })
    );
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    reportType: Yup.string().required(),
    biHeadId: Yup.number().required(),
    analysis: Yup.string().when('reportType', {
      is: (val) => val !== BiReportType.TABLE,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
    orderField: Yup.string().nullable(),
    orderType: Yup.string().nullable(),
    limit: Yup.number().positive().min(1).max(25).nullable(),
    biField: Yup.array().of(
      Yup.object().shape({
        field: Yup.string().required(),
        type: Yup.string().test('is-required-based-on-reportType', 'Kötelező!', function (value) {
          if (this.options?.context) {
            const { reportType } = this.options?.context;
            return reportType === BiReportType.TABLE ? value != null : true;
          } else return false;
        }),
        name: Yup.string().test('is-required-based-on-isFilter', 'Kötelező!', function (value) {
          if (this.options?.parent) {
            const { isFilter } = this.options?.parent;
            return isFilter || !!value;
          } else return false;
        }),
        //@TODO megírni a validációt, ha filter a mező akkor legyen kötelező
        //Yup.string().nullable(),
        filterType: Yup.string()
          .test('is-required-based-on-isFilter', 'Kötelező!', function (value) {
            if (this.options?.parent) {
              const { isFilter } = this.options?.parent;
              return !isFilter || !!value;
            } else return false;
          })
          .nullable(),
        filterFieldValue: Yup.array()
          .of(Yup.string())
          .when('isFilter', {
            is: true,
            then: (schema) => schema.min(1).required('Kötelező!'),
            otherwise: (schema) => schema,
          }),
        /*.min(1) 
          .required(),*/
      })
    ),
  });

  const handleSubmit = (values) => {
    setFieldErrors([]);
    const normalFields = values.biField.filter((field) => !field.isGroup);
    if (normalFields.length) {
      values.limit = parseInt(values.limit);
      biService.upsertBiReportWithFields(values).then((data) => {
        if (!reportId) {
          navigate(`/app/bi/editor/${data.id}`);
        } else {
          setReRender(true);
        }
      });
    } else {
      setFieldErrors([{ id: uuidv4(), message: 'Nincs megadva mező!' }]);
    }
  };

  return (
    <div className="grid grid-cols-1 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem] overflow-auto">
      <div className="col-span-1 bg-white shadow rounded-lg overflow-auto custom-scrollbar">
        <Formik
          initialValues={initValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, validateField, setFieldValue }) => (
            <Form>
              <div className="h-full flex flex-col gap-1 overflow-auto">
                <div className="flex flex-col lg:flex-row gap-1 flex-1 overflow-auto">
                  <div className="lg:flex-[3] xl:flex-[2] 2xl:flex-[2] flex-1 overflow-auto custom-scrollbar">
                    <BiReportForm
                      values={values}
                      validateField={validateField}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      autocompleteData={autocompleteData}
                      getFields={getFields}
                      reportId={reportId}
                      defaultField={defaultField}
                    />
                  </div>
                  <div className="lg:flex-[8] xl:flex-[9] 2xl:flex-[11] flex-1 min-h-[450px] overflow-hidden">
                    {reportId ? (
                      <BiReport reportId={reportId} reRender={reRender} />
                    ) : (
                      <div>A riport mentés után jelenik meg!</div>
                    )}
                  </div>
                </div>

                <div className="flex-1 overflow-auto custom-scrollbar">
                  <BiFieldForm
                    values={values}
                    validateField={validateField}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    defaultField={defaultField}
                    autocompleteData={autocompleteData}
                    fieldErrorState={{ fieldErrors, setFieldErrors }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default BiEditorPage;
