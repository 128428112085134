import React, { useState, useEffect } from 'react';
import useUsers from '../../context/UsersContext';
import FormFields from './FormFields';
import FormTabs from './FormTabs';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI, DialogUI, ButtonUI } from '../Interface';
import Yup from '../../utils/yup';
import masterDataService from '../../service/masterData.service';
import { TaskReportForm } from '../TaskReport/TaskReportForm';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Formik, Form } from 'formik';
import appService from '../../service/app.service';
import simpletaskService from '../../service/simpletask.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';

export const SimpleTaskForm = ({ simpleTaskState, dialog }) => {
  const {
    setRefetchTable,
    selectedValues,
    setSelectedValues,
    viewField,
    setViewField,
    operatorsDisabled,
    setOperatorsDisabled,
    submenu,
    submenuNames,
    params,
    canEdit,
  } = simpleTaskState;

  const { getUser, user } = useUsers();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [memberList, setMemberList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [newMembers, setNewMembers] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [reportList, setReportList] = useState([]);

  const [defaultValues, setDefaultValues] = useState({});
  const [autocompleteData, setAutocompleteData] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});

  useEffect(() => {
    const def = {
      simpleTaskName: '',
      startDate: dayjs(new Date()),
      endDate: dayjs(new Date()).add(1, 'hour'),
      duration: 1,
      preparedness: '',
      machineId: null,
      projectId: null,
      simpleTaskTypeId: null,
      userId: null,
      resposiblePersonId: null,
      statusId: null,
      priorId: null,
      simpleTaskDescription: '',
      companyLocationId: null,
      resourceOneId: null,
      resourceTwoId: null,
      resourceThreeId: null,
    };

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          simpleTaskTypeId: autocompleteData?.type?.defaultValue ?? null,
          statusId: autocompleteData?.status?.defaultValue ?? null,
          priorId: autocompleteData?.priority?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          type: autocompleteData?.type?.defItem,
          status: autocompleteData?.status?.defItem,
          priority: autocompleteData?.priority?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    // ide kell a defaultValues függőségeit pakolni, az effectet kibővíteni
    // Tölteni kell a default értékekhez kapcsolódó leírókkal
    setDefaultValues((prev) => {
      return { ...prev, userId: user.userId };
    });

    setDefaultSelectedValues((prev) => {
      return {
        ...prev,
        user: user,
      };
    });
  }, [user]);

  const validationSchema = Yup.object().shape({
    simpleTaskName: Yup.string().required(),
    startDate: Yup.string().required(),
    endDate: Yup.string().required(),
    duration: Yup.number().positive(),
    preparedness: Yup.number().nullable().positive(),
    machineId: Yup.string().nullable(),
    projectId: Yup.string().nullable(),
    simpleTaskTypeId: Yup.string().required(),
    userId: Yup.string().required(),
    resposiblePersonId: Yup.string().nullable(),
    statusId: Yup.string().required(),
    priorId: Yup.string().required(),
    simpleTaskDescription: Yup.string(),
    companyLocationId: Yup.string().nullable(),
    resourceOneId: Yup.string().nullable(),
    resourceTwoId: Yup.string().nullable(),
    resourceThreeId: Yup.string().nullable(),
  });

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField || !dialog.isUpdate) {
      handleInsertObject(values);
    }
  };

  const handleAddTaskMembers = async (taskMembers, insertId) => {
    const tableName = 'SimpleTaskRespPersonlist';
    const id = 'respPersonSimpleTaskId';

    for (const item of taskMembers) {
      const insertObject = {
        userId: item.userId,
        SimpleTaskId: item.simpleTaskId ?? insertId,
        roleName: item.roleName,
      };

      const insData = { tableName, id, data: insertObject };

      appService
        .addItem(insData)
        .then(() => {
          addNotification({
            content: 'Sikeres mentés!',
            type: NotificationType.SUCCESS,
          });
        })
        .finally(() => {});
    }

    setNewMembers([]);
  };

  const handleFileUpload = async (files, id) => {
    try {
      /*for (const item of files) {
        item.append('SimpleTaskId', id);
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/upload-endpoint`, {
            method: 'POST',
            body: item,
          });

          if (response.ok) {
            const data = await response.json();
          } else {
            console.error('Hiba történt a feltöltés során:', response.statusText);
          }
        } catch (error) {
          console.error('Hiba történt a fetch során:', error);
        }
      }*/
    } catch (error) {
      //console.error('Hiba történt:', error);
    }
    setNewFiles([]);
    //setFileList([]);
  };

  useEffect(() => {
    if (submenu) initDefaultValues();
  }, [submenu]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        setMemberList(selectedValues.simpleTaskRespPersonlist ?? []);
        setFileList(selectedValues?.simpleTaskAttachment ?? []);
        setReportList(selectedValues?.taskReports ?? []);
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  useEffect(() => {
    if (dialog) {
      setViewField(true);
    }
  }, [dialog?.handleClose]);

  const valuesToForm = () => {
    const {
      simpleTaskName,
      startDate,
      endDate,
      duration,
      preparedness,
      machineId,
      projectId,
      simpleTaskTypeId,
      userId,
      resposiblePersonId,
      statusId,
      priorId,
      simpleTaskDescription,
      companyLocationId,
      resourceOneId,
      resourceTwoId,
      resourceThreeId,
    } = selectedValues;

    setInitValues({
      simpleTaskName,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      duration,
      preparedness,
      machineId,
      projectId,
      simpleTaskTypeId,
      userId,
      resposiblePersonId,
      statusId,
      priorId,
      simpleTaskDescription,
      companyLocationId,
      resourceOneId,
      resourceTwoId,
      resourceThreeId,
    });
  };

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .typeToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .statusToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .priorityToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          type: resp[0],
          status: resp[1],
          priority: resp[2],
        };
        setAutocompleteData(aData);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  const updateObject = (values) => {
    const updData = {
      ...values,
      duration: parseFloat(values.duration),
      preparedness: parseFloat(values.preparedness),
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
      updatedBy: user.userId,
    };

    const simpleTaskId = selectedValues.simpleTaskId;

    simpletaskService.updateSimpleTask(updData, simpleTaskId).then((_simpleTask) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setRefetchTable(true);
      handleAddTaskMembers(newMembers, 0);
      handleFileUpload(newFiles, selectedValues.simpleTaskId);
      setEditButtonvisible(true);
      setViewField(true);
      if (dialog) {
        dialog.setTaskChanged(true);
        dialog.handleClose();
      }
    });
  };

  const handleInsertObject = (values) => {
    const insertObject = {
      ...values,
      duration: parseFloat(values.duration),
      preparedness: parseFloat(values.preparedness),
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
      createdBy: user.userId,
    };

    simpletaskService
      .createSimpleTask(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        // @TODO ezeket tudjuk az első inserttel is kezelni!!!
        handleAddTaskMembers(newMembers, data.simpleTaskId);
        handleFileUpload(newFiles, data.simpleTaskId);
        setAddField(true);
        setViewField(true);
        setInitValues({});
        if (dialog) {
          dialog.setTaskChanged(true);
          dialog.handleClose();
        }
      })

      .finally(() => {
        //@TODO loader
      });
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
      deletedAt: dayjs(new Date()),
    };
    simpletaskService.deleteSimpleTask(deleteData, selectedValues.simpleTaskId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultSelectedValues);
      if (dialog) {
        dialog.setTaskChanged(true);
        dialog.handleClose();
      }
    });
  };

  const handleAddReport = (newReport) => {
    newReport.taskType = selectedValues.taskType;
    setReportList((prevList) => [...prevList, newReport]);
    setRefetchTable(true);
  };

  const onAddClick = () => {
    setSelectedValues(defaultSelectedValues);
    setInitValues(defaultValues);
    setViewField(false);
    setAddField(false);
    setMemberList([]);
    setFileList([]);
  };

  const onModifyClick = () => {
    setViewField(false);
    setEditButtonvisible(false);
  };

  const onDeleteClick = () => {
    setShowDeleteDialog(true);
  };

  const onCopyClick = () => {
    setViewField(false);
    setAddField(false);
  };

  const onReportClick = () => {
    setShowDialog(true);
  };

  return (
    <>
      <div className="col-span-4 md:col-span-2 w-full overflow-x-auto overflow-y-auto">
        <div className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg">
          <div className="flex items-center flex-wrap">
            <FormHeaderUI
              addClick={!dialog ? onAddClick : null}
              addDisabled={!viewField || !canEdit}
              modClick={!dialog || dialog.isUpdate ? onModifyClick : null}
              modDisabled={!viewField || operatorsDisabled || !canEdit}
              deleteClick={!dialog || dialog.isUpdate ? onDeleteClick : null}
              deleteDisabled={!viewField || operatorsDisabled || !canEdit}
              copyClick={!dialog || dialog.isUpdate ? onCopyClick : null}
              copyDisabled={!viewField || operatorsDisabled || !canEdit}
            />
            {!dialog && (
              <ButtonUI
                text="Lejelentés"
                size="sm"
                className="bg-labelColor py-3"
                onClick={onReportClick}
                disabled={!viewField || operatorsDisabled}
              />
            )}
          </div>

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
            //validateOnMount={false}
          >
            {({ values, setFieldValue, errors, validateField, resetForm }) => (
              <Form>
                <FormFields
                  viewField={(!dialog || dialog?.isUpdate) && viewField}
                  submenuId={submenu}
                  selectedValues={selectedValues}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  validateField={validateField}
                  params={params}
                  submenuNames={submenuNames}
                />

                <FormTabs
                  values={values}
                  errors={errors}
                  setRefetchTable={setRefetchTable}
                  addField={(!dialog || dialog?.isUpdate) && addField}
                  //Azonosító, hogy melyik form-hoz adjuk hozzá az elemet
                  simpleTaskId={selectedValues?.simpleTaskId}
                  disabled={(!dialog || dialog?.isUpdate) && viewField}
                  //További felelősökhoz tartozó részek
                  memberList={memberList}
                  setMemberList={setMemberList}
                  reportList={reportList}
                  // Drag and Drop tartozó formrészek
                  fileList={fileList}
                  setFileList={setFileList}
                  newFiles={newFiles}
                  setNewFiles={setNewFiles}
                  //Leírás formrészhez szükséges mezők
                  formId={'simpleTaskDescription'}
                  value={initValues.simpleTaskDescription}
                />

                {(!editButtonVisible || !addField || (dialog && !dialog.isUpdate)) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      setNewMembers([]);
                      setNewFiles([]);
                      resetForm();
                      if (dialog) {
                        dialog.handleClose();
                      }
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />

      <DialogUI
        open={showDialog}
        headerContent={'Feladat lejelentő'}
        fullWidth={true}
        onClose={() => setShowDialog(false)}
      >
        <TaskReportForm selectedTask={selectedValues} setShowDialog={setShowDialog} onReportAdd={handleAddReport} />
      </DialogUI>
    </>
  );
};
